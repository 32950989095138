import { Box, SxProps } from '@mui/material';
import { StyledTruncatedTypography } from '@/styled';
import { memo } from 'react';

type Props = {
  title: string;
  items?: string[];
  sxTypo?: SxProps;
};

const JobFilterBadge = memo(({ title, items = [], sxTypo }: Props) => {
  return (
    <Box
      sx={{
        padding: '12px 15px',
        borderRadius: '10px',
        backgroundColor: 'blue1',
        overflow: 'hidden',
      }}
    >
      <StyledTruncatedTypography sx={{ ...sxTypo }} variant={'poppins14Bold'}>
        {title}
        {items?.length ? ':' : ''} {items?.join(', ')}
      </StyledTruncatedTypography>
    </Box>
  );
});

export default JobFilterBadge;

import { UserType } from '@/types/user';
import { TFunction } from 'i18next';

export const getDatingLocationLabel = (
  datingLocation: UserType['datingLocation'] | undefined,
  t: TFunction
) => {
  switch (datingLocation) {
    case 'MOBILE': {
      return t('form.mobile');
    }
    case 'ONSITE': {
      return t('form.onSite');
    }
    case 'BOTH': {
      return `${t('form.mobile')}, ${t('form.onSite')}`;
    }
    default: {
      return `${t('form.mobile')}, ${t('form.onSite')}`;
    }
  }
};

import { Box, Typography } from '@mui/material';
import EditPersonalDetails from '@/pages/ProfilePage/components/talent/EditPersonalDetails.tsx';
import { memo } from 'react';
import { StyledTruncatedTypography } from '@/styled';
import { UserType } from '@/types/user';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type Props = Pick<UserType, 'firstName' | 'lastName' | 'dob' | 'gender'> & {
  isMyProfile: boolean;
};

const TalentPersonalDetails = memo(
  ({ firstName, lastName, dob, gender, isMyProfile }: Props) => {
    const { t } = useTranslation();
    return (
      <Box>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Typography variant={'poppins20Semibold'}>
            {t('common.personalDetails')}
          </Typography>
          {isMyProfile && (
            <EditPersonalDetails
              firstName={firstName}
              lastName={lastName}
              gender={gender}
              dob={dob}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
            marginTop: '25px',
          }}
        >
          {/* Column 1 */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              gap: '10px',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box>
                <Typography
                  sx={{ color: '#92979D' }}
                  variant="poppins16Regular"
                >
                  {t('form.firstName')}
                </Typography>
                <StyledTruncatedTypography variant="poppins16Medium">
                  {firstName || '-'}
                </StyledTruncatedTypography>
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box>
                <StyledTruncatedTypography
                  sx={{ color: '#92979D' }}
                  variant="poppins16Regular"
                >
                  {t('form.lastName')}
                </StyledTruncatedTypography>
                <StyledTruncatedTypography variant="poppins16Medium">
                  {lastName || '-'}
                </StyledTruncatedTypography>
              </Box>
            </Box>
          </Box>

          {/* Column 2 */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              gap: '10px',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box>
                <Typography
                  sx={{ color: '#92979D' }}
                  variant="poppins16Regular"
                >
                  {t('form.gender')}
                </Typography>
                <StyledTruncatedTypography variant="poppins16Medium">
                  {gender || '-'}
                </StyledTruncatedTypography>
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box>
                <Typography
                  sx={{ color: '#92979D' }}
                  variant="poppins16Regular"
                >
                  {t('form.dob')}
                </Typography>
                <StyledTruncatedTypography variant="poppins16Medium">
                  {dayjs(dob).format('MM/DD/YYYY') || '-'}
                </StyledTruncatedTypography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default TalentPersonalDetails;

import useDebouncedSearch from '@/hooks/useDebouncedSearch';
import { usePositionsQuery } from '@/api/user/queries';
import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, debounce } from '@mui/material';
import { StyledOfferingsTextField } from '@/styled';
import SearchIcon from '@/assets/icons/search_icon.svg?react';
import { useLocation, useSearchParams } from 'react-router-dom';

type ExploreOfferingsAutoSuggestProps = {
  positions: any[];
  onDebounce?: (value: string) => void;
  defaultInternalValue: string;
};

const ExploreOfferingsAutoSuggest = ({
  defaultInternalValue,
  positions,
  onDebounce,
}: ExploreOfferingsAutoSuggestProps) => {
  const [internalValue, setInternalValue] = useState(defaultInternalValue);
  const [value, setValue] = useState('');

  const onChangeValue = (value: string) => {
    setValue(value);
  };

  const handleDebouncedValue = useMemo(
    () =>
      debounce(
        (value: string, onDebounce: (value: string) => void) =>
          onDebounce(value),
        500
      ),
    []
  );

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      freeSolo
      options={positions}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      filterOptions={(x) => x}
      autoComplete
      value={{ value: value || '', label: value || '' }}
      inputValue={internalValue}
      onInputChange={(_, newInputValue, reason) => {
        if ((reason === 'input' || reason === 'clear') && onDebounce) {
          setInternalValue(newInputValue);
          handleDebouncedValue(newInputValue, onDebounce);
        }
      }}
      disableClearable
      popupIcon={null}
      onChange={(_, newValue, reason) => {
        if (
          typeof newValue === 'object' &&
          newValue !== null &&
          'value' in newValue &&
          'label' in newValue
        ) {
          onChangeValue(newValue.value || '');
          if (reason === 'selectOption' && onDebounce) {
            setInternalValue(newValue.label);
            handleDebouncedValue(newValue.label, onDebounce);
          }
        }
      }}
      renderInput={(params) => (
        <Box sx={{ position: 'relative', width: '100%' }}>
          <StyledOfferingsTextField placeholder={'Search'} {...params} />
          <Button
            variant={'contained'}
            sx={{
              position: 'absolute',
              right: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <SearchIcon style={{ width: '24px', height: '24px' }} />
          </Button>
        </Box>
      )}
    />
  );
};

const ExploreOfferingsSearch = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchValueQuery = query.get('query') || '';
  const [searchParams, setSearchParams] = useSearchParams();

  const { searchValue, handleDebounceSearchValue } = useDebouncedSearch();

  const { data: positions } = usePositionsQuery(searchValue);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (searchValue) {
      newSearchParams.set('query', searchValue);
      newSearchParams.set('page', '1');
    } else {
      newSearchParams.delete('query');
    }
    setSearchParams(newSearchParams);
  }, [searchValue]);

  return (
    <ExploreOfferingsAutoSuggest
      onDebounce={handleDebounceSearchValue}
      positions={positions || []}
      defaultInternalValue={searchValueQuery}
    />
  );
};

export default ExploreOfferingsSearch;

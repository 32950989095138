import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import ExploreOfferListItem from '@/components/common/ExploreOfferListItem';
import { useOfferingsByTalentQuery } from '@/api/offering/queries.ts';
import ExploreJobsPagination from '@/pages/ExplorePage/components/ExploreJobsPagination.tsx';
import { useSearchParams } from 'react-router-dom';

type Props = {
  talentId?: string;
  isMyOfferings: boolean;
};

const MoreOfferings = ({ talentId, isMyOfferings }: Props) => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') ?? '1';

  const { data: offerings } = useOfferingsByTalentQuery({
    talentId,
    page,
  });

  const { t } = useTranslation();
  return (
    <>
      <Typography
        sx={{ display: 'block', marginBottom: '23px' }}
        variant={'poppins16Semibold'}
      >
        {t(isMyOfferings ? 'common.myOfferings' : 'common.additionalOfferings')}
      </Typography>
      <Grid container spacing={'40px'}>
        {offerings?.data?.map((offering) => (
          <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6 }} key={offering.id}>
            <ExploreOfferListItem
              id={offering.id}
              src={offering.medias?.[0]?.url || ''}
              title={offering.title}
              skills={offering.skills}
              price={offering.price}
              talent={offering.talent}
            />
          </Grid>
        ))}
      </Grid>
      {offerings?.totalPages && (
        <ExploreJobsPagination count={offerings.totalPages} />
      )}
    </>
  );
};

export default MoreOfferings;

import { Avatar, Box, styled, SxProps } from '@mui/material';
import LocationIcon from '@/assets/icons/location_icon.svg?react';
import { StyledTruncatedTypography } from '@/styled';
import { stringAvatar } from '@/utils/getAvatarString';

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

type Props = {
  src: string;
  name: string;
  location: string;
  sx?: SxProps;
  locationSx?: SxProps;
};

const AvatarNameLocation = ({ src, name, location, sx, locationSx }: Props) => {
  return (
    <Wrapper sx={{ display: 'flex', overflow: 'hidden', gap: '8px', ...sx }}>
      <Avatar src={src} {...stringAvatar(name)} />
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column',
          gap: '3px',
        }}
      >
        <StyledTruncatedTypography
          sx={{ whiteSpace: 'normal' }}
          variant={'poppins14Medium'}
        >
          {name}
        </StyledTruncatedTypography>
        <Box sx={{ display: 'flex', gap: '3px', ...locationSx }}>
          <LocationIcon />
          <StyledTruncatedTypography
            variant={'poppins12Regular'}
            sx={{ ...locationSx }}
          >
            {location}
          </StyledTruncatedTypography>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default AvatarNameLocation;

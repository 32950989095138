import { Box, Button } from '@mui/material';
import JobPostingSubcategoriesBlock from '@/components/common/JobPostingForm/JobPostingSubcategoriesBlock.tsx';
import { useFormContext } from 'react-hook-form';
import { SubcategoryType } from '@/types/filters';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';
import { useTranslation } from 'react-i18next';

const SubcategoriesStep = ({
  subcategories,
  onNextButton,
}: {
  onNextButton: () => void;
  subcategories: SubcategoryType[];
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<JobCategoryFormInputs>();
  const subCategoryId = watch('subCategoryId');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginTop: '40px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <JobPostingSubcategoriesBlock
        name={'subCategoryId'}
        subcategories={subcategories}
        isSingleSelect={true}
      />
      <Button
        onClick={onNextButton}
        disabled={!subCategoryId}
        variant={'contained'}
        sx={{ width: '100%', maxWidth: '460px' }}
      >
        {t('form.next')}
      </Button>
    </Box>
  );
};

export default SubcategoriesStep;

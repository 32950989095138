import { ReactNode } from 'react';
import { Box, FormControl } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import UploadAvatar from '@/components/common/UploadAvatar';
import { StyledFormLabel } from '@/components/forms/FormLabel';
import { PhotoFileType } from '@/types/user';

type Props<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: ReactNode;
  horizontal?: boolean;
  squared?: boolean;
  photoFile: PhotoFileType | null;
  onPhotoChange: (photoFile: PhotoFileType) => void;
};

const FormAvatarUpload = <TSchema extends FieldValues>({
  name,
  control,
  label,
  horizontal,
  squared,
  photoFile,
  onPhotoChange,
}: Props<TSchema>) => {
  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <Box
        sx={{
          ...(!squared && {
            display: 'flex',
            gap: '30px',
            alignItems: 'center',
          }),
          ...(squared && { marginBottom: '20px' }),
        }}
      >
        <StyledFormLabel
          sx={{ ...(squared && { marginBottom: '18px' }) }}
          horizontal={horizontal}
          htmlFor={name}
        >
          {label}
        </StyledFormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <UploadAvatar
                id={name}
                imageUrl={photoFile?.url}
                onUpload={(value) => {
                  onChange(value?.id);
                  onPhotoChange(value);
                }}
                squared={squared}
              />
            );
          }}
        />
      </Box>
    </FormControl>
  );
};

export default FormAvatarUpload;

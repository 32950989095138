import { Box, Typography } from '@mui/material';
import EditProfileSkills from '@/pages/ProfilePage/components/talent/EditProfileSkills.tsx';
import { UserType } from '@/types/user';
import JobFilterBadge from '@/pages/ProfilePage/components/hirer/JobFilterBadge.tsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = Pick<UserType, 'skills'> & { isMyProfile: boolean };

const ProfileSkills = memo(({ skills, isMyProfile }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography variant={'poppins20Semibold'}>
          {t('form.skills')}
        </Typography>
        {isMyProfile && <EditProfileSkills skills={skills} />}
      </Box>
      <Box
        sx={{
          marginTop: '5px',
          display: 'flex',
          gap: '20px',
          flexWrap: 'wrap',
        }}
      >
        {skills?.map((el, i) => (
          <JobFilterBadge
            sxTypo={{ fontWeight: 500 }}
            title={el}
            key={`${el}-${i}`}
          />
        ))}
      </Box>
    </>
  );
});

export default ProfileSkills;

import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import FormSelect from '@/components/forms/FormSelect';
import { ExploreFilterFormType } from '@/components/common/ExploreFilter/index.tsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailabilityTypeSelectOptions } from '@/constants';

const JobAvailabilityFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useFormContext<ExploreFilterFormType>();
  const { control, watch, setValue } = methods;
  const type = watch('availability');

  useEffect(() => {
    setValue('availability', searchParams.get('availability') || '');
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (type) {
      newSearchParams.set('availability', type);
      newSearchParams.set('page', '1');
    } else {
      newSearchParams.delete('availability');
    }
    setSearchParams(newSearchParams);
  }, [type]);

  return (
    <FormSelect<ExploreFilterFormType>
      name="availability"
      control={control}
      label={t('form.availability')}
      placeholder={t('form.selectAvailabilityForJob')}
      options={getAvailabilityTypeSelectOptions(t)}
    />
  );
};

export default JobAvailabilityFilter;

import { Box, styled, Typography } from '@mui/material';

const StepContainer = styled(Box)({
  display: 'flex',
  gap: '20px',
  marginTop: '10px',
});

const StepItem = styled(Box)<{ active: number }>(({ theme, active }) => ({
  flex: 1,
  borderRadius: '20px',
  height: '10px',
  backgroundColor: active ? theme.palette.purple1 : '#fff',
  maxWidth: '310px',
}));

const FormCreationStepper = ({
  stepsAmount = 4,
  currentStep,
}: {
  stepsAmount?: number;
  currentStep: number;
}) => {
  const steps = Array.from({ length: stepsAmount }, (_, i) => `step-${i + 1}`);

  return (
    <>
      <StepContainer>
        {steps.map((stepId, index) => (
          <StepItem key={stepId} active={Number(index + 1 === currentStep)} />
        ))}
      </StepContainer>
      <Typography
        sx={{ display: 'block', marginTop: '5px' }}
        variant="poppins16Regular"
      >
        {currentStep} of {stepsAmount}
      </Typography>
    </>
  );
};

export default FormCreationStepper;

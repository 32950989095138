import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import LocationFilter from '@/components/common/ExploreFilter/LocationFilter.tsx';
import GenderFilter from '@/components/common/ExploreFilter/GenderFilter.tsx';
import { useTranslation } from 'react-i18next';
import {
  FilterContainer,
  ResetButton,
} from '@/components/common/ExploreFilter';
import AvailableForFilter from '@/components/common/ExploreFilter/AvailableForFilter.tsx';
import DatingFilter from '@/components/common/ExploreFilter/DatingFilter.tsx';
import AgeRangeFilter from '@/components/common/ExploreFilter/AgeRangeFilter.tsx';
import SkillsFilter from '@/components/common/ExploreFilter/SkillsFilter.tsx';

export type TalentExploreFilterFormType = {
  locationId?: string;
  locationSearch?: string;
  gender?: string;
  availableFor?: string;
  dating?: boolean;
  ageRange?: string;
  skills?: string[];
  [key: string]: any;
};

const filterKeys: (keyof TalentExploreFilterFormType)[] = [
  'locationId',
  'locationSearch',
  'gender',
  'availableFor',
  'dating',
  'ageRange',
];

const resetFilterState = {
  locationId: '',
  locationSearch: '',
  gender: '',
  availableFor: '',
  dating: false,
  ageRange: '',
  skills: [],
};

const TalentsFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm<TalentExploreFilterFormType>();

  const { reset } = methods;
  const onResetFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    filterKeys.forEach((filterKey) => {
      newSearchParams.delete(filterKey as string);
    });
    // Reset page
    newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
    reset(resetFilterState);
  };

  return (
    <FilterContainer>
      <FormProvider {...methods}>
        <LocationFilter />
        <GenderFilter />
        <AvailableForFilter />
        <AgeRangeFilter />
        <DatingFilter />
        <SkillsFilter />
      </FormProvider>
      <ResetButton onClick={onResetFilters}>{t('common.reset')}</ResetButton>
    </FilterContainer>
  );
};

export default TalentsFilter;

import { Box } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const GradientCard = ({ children }: Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '40px',
        position: 'relative',
        borderRadius: '15px',
        background: (theme) => theme.palette.gradient7,
      }}
    >
      {children}
    </Box>
  );
};

export default GradientCard;

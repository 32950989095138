import { Box, FormControl, FormLabel, styled, Typography } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import {ReactNode, useMemo} from 'react';
import MaleGenderIcon from '@/assets/icons/male_gender_icon.svg?react';
import FemaleGenderIcon from '@/assets/icons/female_gender_icon.svg?react';
import OtherGenderIcon from '@/assets/icons/other_gender_icon.svg?react';
import { useTranslation } from 'react-i18next';

export const GenderBox = styled(Box)(() => ({
  backgroundColor: '#fff',
  height: '130px',
  boxShadow: '0px 0px 6px 0px rgba(183, 189, 201, 1) inset',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
  cursor: 'pointer',
  flex: 1,
}));

type Props<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: ReactNode;
};

const FormGender = <TSchema extends FieldValues>({
  name,
  control,
  label,
}: Props<TSchema>) => {
  const { t } = useTranslation();

  const GENDER_ITEMS = useMemo(
    () => [
      {
        icon: <MaleGenderIcon />,
        label: t('gender.Male'),
        value: 'Male',
      },
      {
        icon: <FemaleGenderIcon />,
        label: t('gender.Female'),
        value: 'Female',
      },
      {
        icon: <OtherGenderIcon />,
        label: t('gender.Other'),
        value: 'Other',
      },
    ],
    [t]
  );
  return (
    <FormControl
      component="fieldset"
      sx={{ width: '100%', marginBottom: '20px' }}
    >
      <FormLabel component="legend" sx={{ width: '100%' }}>
        <Typography
          variant={'poppins16Medium'}
          sx={{ marginBottom: '10px', display: 'block', color: '#051320' }}
        >
          {label}
        </Typography>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box sx={{ display: 'flex', gap: '32px', width: '100%' }}>
              {GENDER_ITEMS.map((el) => (
                <GenderBox
                  key={el.value}
                  onClick={() => {
                    onChange(el.value);
                  }}
                >
                  <Box
                    sx={{ color: value === el.value ? '#5369F5' : '#DAE6F4' }}
                  >
                    {el.icon}
                  </Box>
                  <Typography variant={'poppins16Regular'}>
                    {el.label}
                  </Typography>
                </GenderBox>
              ))}
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormGender;

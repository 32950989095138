import PageBackComponent from '@/components/common/PageBackComponent';
import JobPostingForm, {
  JobCategoryFormInputs,
} from '@/components/common/JobPostingForm';
import Layout from '@/components/common/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useJobPostingQuery } from '@/api/jobPosting/queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { editJobPosting } from '@/api/jobPosting/fetchers';
import { handleMutationError } from '@/utils/handleMutationError';
import { toast } from 'sonner';
import { PAGE_ROUTES } from '@/constants';
import FormCreationStepper from '@/components/common/FormCreationStepper';
import { useCreationFormStepsStore } from '@/store/formCreationStepsStore';
import { useTranslation } from 'react-i18next';

const JobEditPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const currentStep = useCreationFormStepsStore((state) => state.currentStep);
  const setPrevNextStep = useCreationFormStepsStore(
    (state) => state.setPrevNextStep
  );
  const setCurrentStep = useCreationFormStepsStore(
    (state) => state.setCurrentStep
  );

  const editJobPostingMutation = useMutation({
    mutationFn: editJobPosting,
    onSuccess: (data) => {
      toast.success(t('form.jobUpdated'));
      queryClient.invalidateQueries({ queryKey: ['jobPosting', data?.id] });
      navigate(`/${PAGE_ROUTES.Profile}`);
      setCurrentStep(1);
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const onSubmit = (data: JobCategoryFormInputs) => {
    const { selectedCategoryId, locationSearch, ...filteredData } = data;
    const filters = filteredData?.data;
    const transformedData = {
      ...filteredData,
      data: [
        ...(filters || []).map((el) => ({
          ...el,
          filterValueId: (el.filterValueId || []).map((el) => el.id),
        })),
      ],
    };
    if (id) {
      editJobPostingMutation.mutate({ id, body: transformedData });
    }
  };

  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate(-1);
    } else {
      setPrevNextStep(-1);
    }
  };

  const { data: job } = useJobPostingQuery(id);

  return (
    <Layout>
      <PageBackComponent onBackButton={handleBackButton} title={t('pageTitles.editJob')} />
      <FormCreationStepper currentStep={currentStep} stepsAmount={4} />
      <JobPostingForm job={job} onSubmit={onSubmit} />
    </Layout>
  );
};

export default JobEditPage;

export const formatCurrency = (
  value: number,
  locale: string = 'en-US', // Default to US English locale
  options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
): string => {
  try {
    // Create a new instance of NumberFormat with the provided locale and options
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
  } catch (error) {
    console.error('Error formatting number:', error);
    return value.toString(); // Return the raw value as a fallback
  }
};

import { UserType } from '@/types/user';
import { talentSchema } from '@/pages/OnboardingPage/components/TalentForm';
import { z } from 'zod';
import { useState } from 'react';
import { useProfileMutation } from '@/api/user/queries';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '@/components/forms/FormInput';
import FormMediaUpload from '@/components/forms/FormMediaUpload';
import EditButton from '@/components/common/EditButton';
import Modal from '@/components/common/Modal';
import EditProfileButtons from '@/pages/ProfilePage/components/EditProfileButtons.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { FieldsContainer } from '@/styled';
import { useTranslation } from 'react-i18next';

export const mediaSchema = talentSchema.pick({
  mediaIds: true,
  videoUrl: true,
});

export type MediaInfoFields = z.infer<typeof mediaSchema>;

type Props = Pick<UserType, 'medias' | 'videoUrl'>;

const EditMediaInfo = ({ medias, videoUrl }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [mediaFiles, setMediaFiles] = useState(medias);
  const [open, setOpen] = useState(false);
  const updateProfileMutation = useProfileMutation(queryClient);

  const methods = useForm<MediaInfoFields>({
    resolver: zodResolver(mediaSchema),
    defaultValues: { mediaIds: medias?.map((el) => el?.id), videoUrl },
    mode: 'onChange',
  });
  const { reset, handleSubmit, control } = methods;

  const handleClose = () => {
    setOpen(false);
    reset({ mediaIds: medias?.map((el) => el?.id), videoUrl });
  };

  const onSubmit = async (data: MediaInfoFields) => {
    updateProfileMutation.mutate(data);
    setOpen(false);
  };
  return (
    <>
      <EditButton
        iconSize={12}
        onClick={() => setOpen(true)}
        sx={{ width: '24px', height: '24px', borderRadius: '99px' }}
      />
      <Modal open={open} onClose={handleClose} title={t('form.editMedia')}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FieldsContainer>
              <FormInput<MediaInfoFields>
                name="videoUrl"
                control={control}
                label={t('form.videoLink')}
                placeholder={t('form.videoLinkPlaceholder')}
              />
              <FormMediaUpload<MediaInfoFields>
                name="mediaIds"
                gridProps={6}
                onMediaChange={(mediaFiles) => {
                  setMediaFiles(mediaFiles);
                }}
                mediaFiles={mediaFiles}
                control={control}
                label={t('form.uploadPhotos')}
              />
              <EditProfileButtons
                isDisabled={updateProfileMutation.isPending}
                handleClose={handleClose}
              />
            </FieldsContainer>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default EditMediaInfo;

import { Button, ButtonProps } from '@mui/material';
import DeleteIcon from '@/assets/icons/trash_can.svg?react';

const RemoveButton = ({
  iconSize = 12,
  ...props
}: ButtonProps & { iconSize?: number }) => {
  return (
    <Button
      variant={'contained'}
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'initial',
        minHeight: 'initial',
        width: '20px',
        height: '20px',
        padding: 0,
        ...props?.sx,
      }}
    >
      <DeleteIcon
        style={{ ...(!!iconSize && { width: iconSize, height: iconSize }) }}
      />
    </Button>
  );
};

export default RemoveButton;

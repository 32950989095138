import { TFunction } from 'i18next';
import { UserType } from '@/types/user';
import { isHirer, isTalent } from '@/utils/roleCheck';

export enum PAGE_ROUTES {
  Registration = 'signup',
  EmailVerification = 'verify-email',
  Onboarding = 'onboarding',
  Login = 'login',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  Landing = '',
  JobPosting = 'job-posting',
  Profile = 'profile',
  JobEdit = 'job-edit',
  Explore = 'explore',
  ExploreJobs = 'jobs',
  ExploreTalents = 'talents',
  ExploreOfferings = 'offerings',
  JobDetails = 'job-details',
  OfferingDetails = 'offering-details',
  OfferingCreation = 'offering-creation',
  OfferingEdit = 'offering-edit',
  SavedProfiles = 'saved-profiles',
  SavedOfferings = 'saved-offerings',
  SavedJobs = 'saved-jobs',
  Talent = 'talent',
}

export const JOB_TYPE = ['WEEKLY', 'HOURLY', 'FIXED'] as const;
export const OFFERING_TYPE = ['HOURLY', 'FIXED'] as const;
export const AVAILABILITY_TYPE = ['VIDEO', 'AUDIO', 'NONE'] as const;
export const GENDER_TYPE = ['Male', 'Female', 'Other'] as const;
export const AGE_RANGE_TYPE = [
  '0–12',
  '13–17',
  '18–24',
  '25–34',
  '35–49',
  '50+',
] as const;

export const DATING_LOCATION = ['ONSITE' , 'MOBILE' , 'BOTH'] as const;
export const AVAILABILITY_JOB_TYPE = [
  'CONTRACT',
  'FREELANCE',
  'FULL_TIME',
  'PART_TIME',
] as const;

export const getAvailabilitySelectOptions = (t: TFunction) => [
  { value: 'VIDEO', label: t('form.videoCall') },
  { value: 'AUDIO', label: t('form.audioCall') },
  { value: 'NONE', label: t('form.none') },
];

export const getAgeRangeSelectOptions = () => [
  { value: '0-12', label: '0-12' },
  { value: '13–17', label: '13–17' },
  { value: '18–24', label: '18–24' },
  { value: '25–34', label: '25–34' },
  { value: '35–49', label: '35–49' },
  { value: '50+', label: '50+' },
];

export const getPriceTypeSelectOptions = (t: TFunction) => [
  { value: 'FIXED', label: t('form.fixed') },
  { value: 'HOURLY', label: t('form.hourly') },
  { value: 'WEEKLY', label: t('form.weekly') },
];

export const getAvailabilityTypeSelectOptions = (t: TFunction) => [
  { value: 'CONTRACT', label: t('form.contract') },
  { value: 'FREELANCE', label: t('form.freelance') },
  { value: 'FULL_TIME', label: t('form.fullTime') },
  { value: 'PART_TIME', label: t('form.partTime') },
];

export const getGenderTypeSelectOptions = (t: TFunction) => [
  { value: 'Male', label: t('gender.Male') },
  { value: 'Female', label: t('gender.Female') },
  { value: 'Other', label: t('gender.Other') },
];

export const getOfferingTypeSelectOptions = (t: TFunction) => [
  { value: 'FIXED', label: t('form.fixed') },
  { value: 'HOURLY', label: t('form.hourly') },
];

export const COMPANY_SIZE_MARKS = [
  { value: 0, label: '0-50' },
  { value: 1, label: '50-100' },
  { value: 2, label: '100-500' },
  { value: 3, label: '500-1000' },
  { value: 4, label: '1000+' },
];

export const getExploreTabs = (t: TFunction, role: UserType['role'] | null) => {
  if (isHirer(role)) {
    return [
      {
        label: t('landingPage.exploreOfferings'),
        navigateTo: `./${PAGE_ROUTES.ExploreOfferings}`,
      },
      {
        label: t('landingPage.exploreTalents'),
        navigateTo: `./${PAGE_ROUTES.ExploreTalents}`,
      },
    ];
  }

  if (isTalent(role)) {
    return [
      {
        label: t('landingPage.exploreJobs'),
        navigateTo: `./${PAGE_ROUTES.ExploreJobs}`,
      },
      {
        label: t('landingPage.exploreOfferings'),
        navigateTo: `./${PAGE_ROUTES.ExploreOfferings}`,
      },
    ];
  }
  return [];
};

export const getTalentHeaderNavigation = (t: TFunction) => {
  return [
    {
      text: t('landingPage.exploreJobs'),
      linkTo: `/${PAGE_ROUTES.Explore}/${PAGE_ROUTES.ExploreJobs}`,
    },
    {
      text: t('navigation.listYourOfferings'),
      linkTo: `/${PAGE_ROUTES.OfferingCreation}`,
    },
  ];
};

export const getHirerHeaderNavigation = (t: TFunction) => {
  return [
    {
      text: t('landingPage.exploreOfferings'),
      linkTo: `/${PAGE_ROUTES.Explore}/${PAGE_ROUTES.ExploreOfferings}`,
    },
    {
      text: t('landingPage.postJob'),
      linkTo: `/${PAGE_ROUTES.JobPosting}`,
    },
  ];
};


export const SOCIAL_MEDIA_REGEXP = {
  facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/i,
  whatsapp: /^(https?:\/\/)?(wa\.me|whatsapp\.com)\/.+$|^\+?[1-9]\d{1,14}$/,
  youtube: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/i,
  linkedin: /^(https?:\/\/)?([\w]+\.)?linkedin\.com\/.+$/i,
};

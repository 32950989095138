import { Box } from '@mui/material';
import PageBackComponent from '@/components/common/PageBackComponent';
import { ProfileContainer } from '@/pages/ProfilePage/components/hirer/HirerProfile.tsx';
import OfferingDetailsProfile from '@/pages/OfferingDetailsPage/components/OfferingDetailsProfile.tsx';
import { useAuthStore } from '@/store/authStore';
import { useUserProfile } from '@/api/user/queries';
import TalentProfileDetailsSection from '@/pages/ProfilePage/components/talent/TalentProfileDetailsSection.tsx';
import { useTranslation } from 'react-i18next';

const TalentProfile = () => {
  const isAuth = useAuthStore((state) => state.isAuth);
  const { data: profile } = useUserProfile(isAuth);
  const { t } = useTranslation();

  return (
    <Box>
      <PageBackComponent title={t('pageTitles.talentProfile')} />
      {!!profile && (
        <ProfileContainer>
          <OfferingDetailsProfile isMyProfile={true} />
          <TalentProfileDetailsSection isMyProfile profile={profile} />
        </ProfileContainer>
      )}
    </Box>
  );
};

export default TalentProfile;

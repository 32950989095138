import FormDating from '@/components/forms/FormDating';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { TalentExploreFilterFormType } from '@/pages/ExplorePage/components/TalentsFilter.tsx';
import { useEffect } from 'react';

const DatingFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useFormContext<TalentExploreFilterFormType>();
  const { control, watch, setValue } = methods;
  const type = watch('dating');

  useEffect(() => {
    setValue('dating', searchParams.get('dating') === 'true');
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (type) {
      newSearchParams.set('dating', `${type}`);
      newSearchParams.set('page', '1');
    } else {
      newSearchParams.delete('dating');
    }
    setSearchParams(newSearchParams);
  }, [type]);
  return (
    <FormDating<TalentExploreFilterFormType>
      name="dating"
      control={control}
      label={t('form.dating')}
    />
  );
};

export default DatingFilter;

import { UserType } from '@/types/user';
import { Box, Typography } from '@mui/material';
import EditMediaInfo from '@/pages/ProfilePage/components/talent/EditMediaInfo.tsx';
import { memo } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useTranslation } from 'react-i18next';

type Props = Pick<UserType, 'medias' | 'videoUrl'> & { isMyProfile: boolean };

const MediaInfo = memo(({ medias, videoUrl, isMyProfile }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography variant={'poppins20Semibold'}>{t('form.media')}</Typography>
        {isMyProfile && <EditMediaInfo videoUrl={videoUrl} medias={medias} />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: { xs: 'column', md: 'row' },
          marginTop: '15px',
        }}
      >
        {videoUrl && (
          <Box
            sx={{
              display: 'flex',
              borderRadius: '10px',
              overflow: 'hidden',
              width: '200px',
              height: '160px',
            }}
          >
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              controls
              url={videoUrl}
            />
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '12px',
            flexWrap: 'wrap',
            maxWidth: '490px',
          }}
        >
          {medias?.map((el) => (
            <Box
              key={el.id}
              component="img"
              src={el.url}
              sx={{
                width: { xs: '100%', sm: 75 },
                height: { xs: '150px', sm: 75 },
                objectFit: 'cover',
                borderRadius: '12px',
              }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
});

export default MediaInfo;

import { useTranslation } from 'react-i18next';
import {Select, MenuItem, SelectChangeEvent} from '@mui/material';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={i18n.language}
      onChange={handleChange}
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="es">Español</MenuItem>
      <MenuItem value="zh">中文</MenuItem>
      <MenuItem value="tl">Tagalog</MenuItem>
      <MenuItem value="vi">Tiếng Việt</MenuItem>
      <MenuItem value="fr">Français</MenuItem>
      <MenuItem value="ar">العربية</MenuItem>
      <MenuItem value="ko">한국어</MenuItem>
      <MenuItem value="ru">Русский</MenuItem>
      <MenuItem value="de">Deutsch</MenuItem>
      <MenuItem value="it">Italiano</MenuItem>
    </Select>
  );
};

export default LanguageSelector;

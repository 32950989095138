import { Controller, Path, useFormContext } from 'react-hook-form';
import SelectableList from '@/components/common/SelectableList';
import CollapsibleBlock from '@/components/common/CollapsibleBlock';
import { FilterType } from '@/types/filters';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';

type Props = {
  name: Path<JobCategoryFormInputs>;
  filter: FilterType;
  isSingleSelect?: boolean;
};

const JobPostingFilterBlock = ({
  filter,
  isSingleSelect = false,
  name,
}: Props) => {
  const { control, watch } = useFormContext<JobCategoryFormInputs>();
  const selectedFilters = watch('data') || [];

  const currentFilter = selectedFilters.find((f) => f.filterId === filter.id);
  const selectedValues = currentFilter?.filterValueId || [];

  return (
    <CollapsibleBlock title={filter.name}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectableList
            items={filter?.filterValues}
            selectedValues={selectedValues}
            isSingleSelect={isSingleSelect}
            onChange={(updatedValues) => {
              const updatedFilters = [
                ...selectedFilters.filter((f) => f.filterId !== filter.id),
                { filterId: filter.id, filterValueId: updatedValues },
              ];
              field.onChange(updatedFilters);
            }}
          />
        )}
      />
    </CollapsibleBlock>
  );
};

export default JobPostingFilterBlock;

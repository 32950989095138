import { UserType } from '@/types/user';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useProfileMutation } from '@/api/user/queries';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { talentSchema } from '@/pages/OnboardingPage/components/TalentForm';
import { z } from 'zod';
import EditButton from '@/components/common/EditButton';
import Modal from '@/components/common/Modal';
import EditProfileButtons from '@/pages/ProfilePage/components/EditProfileButtons.tsx';
import FormSkills from '@/components/forms/FormSkills';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const skillsSchema = talentSchema.pick({
  skills: true,
});

type SkillsField = z.infer<typeof skillsSchema>;

type Props = Pick<UserType, 'skills'>;

const EditProfileSkills = ({ skills }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const updateProfileMutation = useProfileMutation(queryClient);

  const methods = useForm<SkillsField>({
    resolver: zodResolver(skillsSchema),
    defaultValues: { skills },
    mode: 'onChange',
  });
  const { reset, handleSubmit, control } = methods;

  const handleClose = () => {
    setOpen(false);
    reset({ skills });
  };

  const onSubmit = async (data: SkillsField) => {
    updateProfileMutation.mutate(data);
    setOpen(false);
  };
  return (
    <>
      <EditButton
        iconSize={12}
        onClick={() => setOpen(true)}
        sx={{ width: '24px', height: '24px', borderRadius: '99px' }}
      />
      <Modal open={open} onClose={handleClose} title={t('form.editSkills')}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '30px' }}>
              <FormSkills<SkillsField> name={'skills'} control={control} />
            </Box>
            <EditProfileButtons
              isDisabled={updateProfileMutation.isPending}
              handleClose={handleClose}
            />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default EditProfileSkills;

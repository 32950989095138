import { useQuery } from '@tanstack/react-query';
import {fetchTalent, fetchTalents} from '@/api/talents/fetchers.ts';

export const useTalentsQuery = (queryParams: {
  categoryId?: string;
  query?: string;
}) => {
  return useQuery({
    queryKey: ['talents', queryParams],
    queryFn: () => fetchTalents(queryParams),
  });
};


export const useTalentDetailsQuery = (id: string) => {
  return useQuery({
    queryKey: ['talent', id],
    queryFn: () => fetchTalent(id),
    enabled: !!id
  });
};

import { useParams } from 'react-router-dom';
import Layout from '@/components/common/Layout';
import { Box } from '@mui/material';
import PageBackComponent from '@/components/common/PageBackComponent';
import { ProfileContainer } from '@/pages/ProfilePage/components/hirer/HirerProfile.tsx';
import OfferingDetailsProfile from '@/pages/OfferingDetailsPage/components/OfferingDetailsProfile.tsx';
import OfferingDetailsSection from '@/pages/OfferingDetailsPage/components/OfferingDetailsSection.tsx';
import { useTranslation } from 'react-i18next';
import { useOfferingDetailsQuery } from '@/api/offering/queries';
import { useUserProfile } from '@/api/user/queries';

const OfferingDetailsPage = () => {
  const { data: profile } = useUserProfile(true);
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: offering } = useOfferingDetailsQuery(id);
  const isMyOffer = profile?.id === offering?.talent?.id;

  return (
    <Layout>
      <Box>
        <PageBackComponent title={t('pageTitles.offeringDetails')} />
        {offering && (
          <ProfileContainer>
            <OfferingDetailsProfile
              talent={offering.talent}
              isMyProfile={isMyOffer}
              isOfferingDetails
            />
            <OfferingDetailsSection
              talentId={offering.talent.id}
              isMyOffer={isMyOffer}
              price={offering.price}
              type={offering.type}
              skills={offering.skills}
              title={offering.title}
              description={offering.description}
              medias={offering.medias}
              isFavorite={offering.isFavorite}
            />
          </ProfileContainer>
        )}
      </Box>
    </Layout>
  );
};

export default OfferingDetailsPage;

import { Box, RadioGroup, styled, Typography } from '@mui/material';
import { UserType } from '@/types/user';
import { BpRadio } from '@/styled';
import {useTranslation} from "react-i18next";

const LOGIN_AS_GROUP: { label: string; value: UserType['role'] }[] = [
  { label: 'Talent', value: 'Talent' },
  { label: 'Hirer', value: 'Hirer' },
];

const RadioItemLabel = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const RadioBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '#fff',
  flex: '1',
  borderRadius: '10px',
  padding: '11px',
  outlineWidth: '2px',
  outlineStyle: 'solid',
}));

type Props = {
  onChange: (value: UserType['role']) => void;
  value: UserType['role'];
  error?: string;
};

const LoginAsField = ({ onChange, value }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginY: '25px' }}>
      <Typography sx={{ marginBottom: '10px' }}>{t('role.loginAs')}</Typography>
      <RadioGroup sx={{ gap: '20px' }} row>
        {LOGIN_AS_GROUP.map((item) => (
          <RadioBox
            key={item.value}
            onClick={() => {
              onChange(item.value as UserType['role']);
            }}
            sx={{
              outlineColor: (theme) =>
                value !== item.value ? '#fff' : theme.palette.purple1,
            }}
          >
            <RadioItemLabel>
              <BpRadio
                disableRipple
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(item.value as UserType['role']);
                }}
                value={item.value}
                checked={value === item.value}
              />
              <Typography>{t(`role.${item.label.toLowerCase()}`)}</Typography>
            </RadioItemLabel>
          </RadioBox>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default LoginAsField;

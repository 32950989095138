import { Controller, Path, useFormContext } from 'react-hook-form';
import SelectableList from '@/components/common/SelectableList';
import CollapsibleBlock from '@/components/common/CollapsibleBlock';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';
import { useTranslation } from 'react-i18next';

type Props = {
  name: Path<JobCategoryFormInputs>;
  subcategories: {
    id: string;
    name: string;
  }[];
  isSingleSelect?: boolean;
};

const JobPostingSubcategoriesBlock = ({
  subcategories,
  isSingleSelect = false,
  name,
}: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext<JobCategoryFormInputs>();
  const subCategoryId = watch('subCategoryId') || '';

  const selectedCategory = subcategories.find((f) => f.id === subCategoryId);

  return (
    <CollapsibleBlock title={t('jobPosting.subcategories')}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectableList
            items={subcategories}
            selectedValues={selectedCategory ? [selectedCategory] : []}
            isSingleSelect={isSingleSelect}
            onChange={(updatedValues) => {
              field.onChange(updatedValues?.[0]?.id);
              setValue('data', []);
            }}
          />
        )}
      />
    </CollapsibleBlock>
  );
};

export default JobPostingSubcategoriesBlock;

import React, { useState } from 'react';
import { Box, Button, FormControl, Typography } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import PlusIcon from '@/assets/icons/plus_icon.svg?react';
import { StyledInput } from '@/styled';
import Chip from '@/components/common/Chip';
import { StyledFormLabel } from '@/components/forms/FormLabel';
import { useTranslation } from 'react-i18next';

type SkillsInputProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: React.ReactNode;
  column?: boolean;
};

const SkillsInput = <TSchema extends FieldValues>({
  name,
  control,
  label,
  column = false,
}: SkillsInputProps<TSchema>) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  return (
    <FormControl
      component="fieldset"
      sx={{ width: '100%', paddingBottom: '20px' }}
    >
      <StyledFormLabel htmlFor={name}>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px 20px',
              }}
            >
              {value?.map((skill: string, index: number) => (
                <Chip
                  key={`${skill}-${index}`}
                  value={skill}
                  onDelete={() =>
                    onChange(
                      value.filter((_: string, i: number) => i !== index)
                    )
                  }
                />
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                ...(column && {
                  flexDirection: 'column',
                  alignItems: 'end',
                }),
              }}
            >
              <StyledInput
                id={name}
                sx={{
                  flex: 1,
                  ...(column && { width: '100%' }),
                }}
                placeholder={t('form.addSkillsPlaceholder')}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (inputValue.trim()) {
                    onChange([...(value || []), inputValue.trim()]);
                    setInputValue('');
                  }
                }}
                disabled={!inputValue}
                sx={{
                  display: 'flex',
                  flex: 1,
                  gap: '10px',
                  maxWidth: '150px',
                  ...(column && { maxWidth: 'initial', width: '100%' }),
                }}
              >
                <PlusIcon />
                {t('form.addButton')}
              </Button>
            </Box>
            <Typography
              variant={'error'}
              sx={{ opacity: error?.message ? 1 : 0 }}
            >
              {error?.message ?? 'helper text'}
            </Typography>
          </Box>
        )}
      />
    </FormControl>
  );
};

export default SkillsInput;

import { Button, ButtonProps } from '@mui/material';
import EditIcon from '@/assets/icons/edit_icon.svg?react';

const EditButton = ({
  iconSize = 12,
  ...props
}: ButtonProps & { iconSize?: number }) => {
  return (
    <Button
      variant={'contained'}
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'initial',
        minHeight: 'initial',
        width: '24px',
        height: '24px',
        padding: 0,
        ...props?.sx,
      }}
    >
      <EditIcon
        style={{ ...(!!iconSize && { width: iconSize, height: iconSize }) }}
      />
    </Button>
  );
};

export default EditButton;

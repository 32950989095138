import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyledCheckbox } from '@/styled';
import { useTranslation } from 'react-i18next';
import { DATING_LOCATION } from '@/constants';
import FormFieldWrapper from '@/components/forms/FormFieldWrapper';

type DatingProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: React.ReactNode;
};

const FormDatingLocation = <
  TSchema extends {
    datingLocation?: (typeof DATING_LOCATION)[number];
  } & FieldValues,
>({
  name,
  label,
  control,
}: DatingProps<TSchema>) => {
  const { t } = useTranslation();
  return (
    <FormFieldWrapper name={name} label={label}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const toggleSelection = (option: 'ONSITE' | 'MOBILE') => {
            if (value === 'BOTH') {
              onChange(option === 'ONSITE' ? 'MOBILE' : 'ONSITE');
            } else if (value === option) {
              onChange(option === 'ONSITE' ? 'MOBILE' : 'ONSITE'); // Uncheck the option
            } else if (!value) {
              onChange(option); // First selection
            } else {
              onChange('BOTH'); // Both selected
            }
          };

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => toggleSelection('ONSITE')}
              >
                <Typography variant="poppins16Regular">
                  {t('form.onSite')}
                </Typography>
                <StyledCheckbox
                  id={`${name}-onsite`}
                  checked={value === 'ONSITE' || value === 'BOTH'}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => toggleSelection('MOBILE')}
              >
                <Typography variant="poppins16Regular">
                  {t('form.mobile')}
                </Typography>
                <StyledCheckbox
                  id={`${name}-mobile`}
                  checked={value === 'MOBILE' || value === 'BOTH'}
                />
              </Box>
            </Box>
          );
        }}
      />
    </FormFieldWrapper>
  );
};

export default FormDatingLocation;

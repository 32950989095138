import { useFormContext } from 'react-hook-form';
import { TalentFields } from '@/pages/OnboardingPage/components/TalentForm/index.tsx';
import { FieldsContainer } from '@/styled';
import FormInput from '@/components/forms/FormInput';
import FormMediaUpload from '@/components/forms/FormMediaUpload';
import { PhotoFileType } from '@/types/user';
import { useTranslation } from 'react-i18next';

type Props = {
  mediaFiles: PhotoFileType[];
  onMediaChange: (mediaFiles: PhotoFileType[]) => void;
};

const FourthTalentStep = ({ mediaFiles, onMediaChange }: Props) => {
  const { control } = useFormContext<TalentFields>();
  const { t } = useTranslation();
  return (
    <FieldsContainer>
      <FormInput<TalentFields>
        name="videoUrl"
        control={control}
        label={t('form.videoLink')}
        placeholder={t('form.videoLinkPlaceholder')}
      />
      <FormMediaUpload<TalentFields>
        name="mediaIds"
        onMediaChange={onMediaChange}
        mediaFiles={mediaFiles}
        control={control}
        label={t('form.uploadPhotos')}
      />
    </FieldsContainer>
  );
};

export default FourthTalentStep;

import useDebouncedSearch from '@/hooks/useDebouncedSearch';
import FormAutoSuggest from '@/components/forms/FormAutoSuggest';
import { Control, useFormContext } from 'react-hook-form';
import { useLocationsQuery } from '@/api/auth/queries';
import { useEffect } from 'react';
import { TalentFields } from '@/pages/OnboardingPage/components/TalentForm';
import { useTranslation } from 'react-i18next';

const LocationAutoSuggest = ({
  control,
  placeholder,
}: {
  control: Control<any>;
  placeholder?: string;
}) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<TalentFields>();
  const locationSearch = watch('locationSearch');

  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(locationSearch);

  useEffect(() => {
    setValue('locationSearch', searchValue); // to have location search persistent
    if (!searchValue) {
      setValue('locationId', '');
    }
  }, [searchValue]);

  const { data: locations } = useLocationsQuery(searchValue);
  const translatedPlaceholder = placeholder ?? t('form.locationPlaceholder');
  return (
    <FormAutoSuggest
      searchInputValue={locationSearch}
      name="locationId"
      control={control}
      label={t('form.location')}
      placeholder={translatedPlaceholder}
      options={locations || []}
      onDebounce={handleDebounceSearchValue}
    />
  );
};

export default LocationAutoSuggest;

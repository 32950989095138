import Modal from '@/components/common/Modal';
import { Box, Button, Typography } from '@mui/material';
import ConfirmIcon from '@/assets/icons/modal_confirm_icon.svg?react';
import { useTranslation } from 'react-i18next';

type Props = {
  openConfirmation: boolean;
  handleClose: () => void;
  title: string;
  congratulationText: string;
  info: string;
};

const CreationConfirmationModal = ({
  openConfirmation,
  handleClose,
  title,
  congratulationText,
  info,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal open={openConfirmation} onClose={handleClose}>
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <ConfirmIcon />
        </Box>
        <Typography
          sx={{ textAlign: 'center', marginTop: '20px' }}
          variant={'poppins24Medium'}
        >
          {title}
        </Typography>
        <Typography
          sx={{ textAlign: 'center', marginTop: '20px' }}
          variant={'poppins16Regular'}
        >
          {congratulationText}
        </Typography>
        <Typography
          sx={{ textAlign: 'center', marginTop: '20px' }}
          variant={'poppins16Regular'}
        >
          {info}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ maxWidth: '200px', width: '100%', marginTop: '40px' }}
            onClick={handleClose}
            variant={'contained'}
          >
            {t('common.okay')}
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default CreationConfirmationModal;

import { Box, Typography } from '@mui/material';
import EditAboutInfo from '@/pages/ProfilePage/components/talent/EditAboutInfo.tsx';
import { UserType } from '@/types/user';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { parseDescription } from '@/utils/parseDescription.tsx';

type Props = Pick<UserType, 'bio'> & { isMyProfile: boolean };

const AboutInfo = memo(({ bio, isMyProfile }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography variant={'poppins20Semibold'}>{t('form.about')}</Typography>
        {isMyProfile && <EditAboutInfo bio={bio} />}
      </Box>
      <Typography
        sx={{ display: 'block', marginTop: '15px', whiteSpace: 'pre-line' }}
        variant={'poppins16Regular'}
      >
        {parseDescription(bio)}
      </Typography>
    </>
  );
});

export default AboutInfo;

import { useFormContext } from 'react-hook-form';
import { OfferingFormInputs } from '@/components/common/OfferingCreationForm';
import FormInput from '@/components/forms/FormInput';
import { Box } from '@mui/material';
import FormSelect from '@/components/forms/FormSelect';
import { getOfferingTypeSelectOptions } from '@/constants';
import FormMediaUpload from '@/components/forms/FormMediaUpload';
import { FieldsContainer } from '@/styled';
import { useState } from 'react';
import { PhotoFileType } from '@/types/user';
import FormSkills from '@/components/forms/FormSkills';
import { useTranslation } from 'react-i18next';
import CreationFormButtons from '@/components/common/CreationFormButtons';

const OfferingDetailsStep = ({
  initialMediaFiles,
}: {
  initialMediaFiles?: PhotoFileType[];
}) => {
  const { t } = useTranslation();
  const [mediaFiles, setMediaFiles] = useState<PhotoFileType[]>(
    initialMediaFiles || []
  );

  const { control } = useFormContext<OfferingFormInputs>();
  return (
    <FieldsContainer sx={{ gap: '25px' }}>
      <FormInput<OfferingFormInputs>
        name="title"
        control={control}
        label={t('form.offeringTitle')}
        placeholder={t('form.enterOfferingTitle')}
      />
      <Box
        sx={{
          display: 'flex',
          gap: { md: '90px', xs: '25px' },
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormSelect<OfferingFormInputs>
            name="type"
            control={control}
            label={t('form.offeringType')}
            placeholder={t('form.selectOfferingType')}
            options={getOfferingTypeSelectOptions(t)}
          />
        </Box>
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormInput<OfferingFormInputs>
            name="price"
            control={control}
            label={t('form.price')}
            placeholder={t('form.enterPrice')}
            inputProps={{ min: 0, max: 10000000 }}
            type={'number'}
            startIcon={'$'}
            digitsOnly
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', maxWidth: '484px' }}>
        <FormMediaUpload<OfferingFormInputs>
          name="mediaIds"
          onMediaChange={(mediaFiles) => {
            setMediaFiles(mediaFiles);
          }}
          mediaFiles={mediaFiles}
          control={control}
          label={t('form.uploadPhotos')}
        />
      </Box>
      <FormInput<OfferingFormInputs>
        name="description"
        control={control}
        label={t('form.offeringDescription')}
        multiline
        rows={4}
        placeholder={t('form.enterOfferingDescription')}
      />
      <Box sx={{ maxWidth: '550px', width: '100%' }}>
        <FormSkills<OfferingFormInputs> name={'skills'} control={control} />
      </Box>
      {/*<FormVisibility<OfferingFormInputs>*/}
      {/*  name={'private'}*/}
      {/*  control={control}*/}
      {/*  label={'Offering Visibility'}*/}
      {/*/>*/}
      <CreationFormButtons />
    </FieldsContainer>
  );
};

export default OfferingDetailsStep;

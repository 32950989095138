import { Avatar, Box, Typography } from '@mui/material';
import { StyledTruncatedTypography } from '@/styled';
import LocationIcon from '@/assets/icons/location_icon.svg?react';
import JobFilterBadge from '@/pages/ProfilePage/components/hirer/JobFilterBadge.tsx';
import EditButton from '@/components/common/EditButton';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import RemoveButton from '@/components/common/RemoveButton';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteJobPosting } from '@/api/jobPosting/fetchers';
import { handleMutationError } from '@/utils/handleMutationError';
import { toast } from 'sonner';
import { stringAvatar } from '@/utils/getAvatarString';
import { getLocationString } from '@/utils/getLocationString';
import { JobDetailsType } from '@/api/jobPosting/types';
import { parseDescription } from '@/utils/parseDescription.tsx';
import { useTranslation } from 'react-i18next';

type Props = {
  job: JobDetailsType;
  author: {
    src: string;
    name: string;
  };
};

const JobPostedItem = ({ job, author }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const deleteJobPostingMutation = useMutation({
    mutationFn: deleteJobPosting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myJobs'] });

      toast.success(t('form.jobDeleted'));
      handleCloseConfirmation();
    },
    onError: (error) => {
      handleMutationError(error);
      handleCloseConfirmation();
    },
  });

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate(`/${PAGE_ROUTES.JobEdit}/${job.id}`);
  };

  const handleDelete = () => {
    deleteJobPostingMutation.mutate(job.id);
  };

  const jobLocation = getLocationString(job?.location);

  const parsedDescription = parseDescription(job?.description);

  return (
    <Box
      sx={{
        width: '100%',
        padding: '40px 30px',
        position: 'relative',
        borderRadius: '15px',
        background: (theme) => theme.palette.gradient7,
      }}
    >
      <StyledTruncatedTypography variant={'poppins24Medium'}>
        {job?.title}
      </StyledTruncatedTypography>
      <Typography
        sx={{ display: 'block', marginTop: '20px' }}
        variant={'poppins13Bold'}
      >
        {parsedDescription}
      </Typography>
      <Box sx={{ display: 'flex', marginTop: '20px', gap: '8px' }}>
        <Avatar src={author?.src} {...stringAvatar(author?.name)} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <Typography variant={'poppins14Bold'}>{author?.name}</Typography>
          <Box sx={{ display: 'flex', gap: '3px' }}>
            <LocationIcon />
            <Typography variant={'poppins12Regular'}>{jobLocation}</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        {job?.data?.map((el) => (
          <JobFilterBadge
            key={el.name}
            title={el.name}
            items={el?.values?.map((el) => el?.value)}
          />
        ))}
      </Box>
      <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
        <EditButton
          sx={{ width: '30px', height: '30px' }}
          onClick={handleEditClick}
        />
      </Box>
      <Box sx={{ position: 'absolute', bottom: '10px', right: '10px' }}>
        <RemoveButton
          sx={{ width: '30px', height: '30px' }}
          onClick={handleOpenConfirmation}
        />
      </Box>
      <ConfirmationModal
        title={t('form.deleteJobPosting')}
        subtitle={t('form.deleteJobPostingSubtitle')}
        cancelButtonText={t('form.cancel')}
        submitButtonText={t('form.delete')}
        cancelButtonClick={handleCloseConfirmation}
        submitButtonClick={handleDelete}
        open={openConfirmation}
      />
    </Box>
  );
};

export default JobPostedItem;

import Layout from '@/components/common/Layout';
import { Box } from '@mui/material';
import PageBackComponent from '@/components/common/PageBackComponent';
import { ProfileContainer } from '@/pages/ProfilePage/components/hirer/HirerProfile.tsx';
import OfferingDetailsProfile from '@/pages/OfferingDetailsPage/components/OfferingDetailsProfile.tsx';
import TalentProfileDetailsSection from '@/pages/ProfilePage/components/talent/TalentProfileDetailsSection.tsx';
import { useTranslation } from 'react-i18next';
import { useTalentDetailsQuery } from '@/api/talents/queries.ts';
import { useParams } from 'react-router-dom';

const TalentDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: profile } = useTalentDetailsQuery(id ?? '');
  return (
    <Layout>
      <Box>
        <PageBackComponent title={t('pageTitles.talentProfile')} />
        {!!profile && (
          <ProfileContainer>
            <OfferingDetailsProfile isMyProfile={false} talent={profile} />
            <TalentProfileDetailsSection isMyProfile={false} profile={profile} />
          </ProfileContainer>
        )}
      </Box>
    </Layout>
  );
};

export default TalentDetailsPage;

import AuthLayout from '@/components/common/AuthLayout';
import { FormCardBox } from '@/styled';
import { Typography } from '@mui/material';
import LoginForm from '@/pages/LoginPage/component/LoginForm.tsx';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <FormCardBox>
        <Typography
          sx={{ textAlign: 'center', display: 'block', marginBottom: '40px' }}
          variant={'poppins30Semibold'}
        >
          {t('common.welcomeBack')}
        </Typography>
        <LoginForm />
      </FormCardBox>
    </AuthLayout>
  );
};

export default LoginPage;

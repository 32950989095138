import FormInput from '@/components/forms/FormInput';
import { useFormContext } from 'react-hook-form';
import { FieldsContainer } from '@/styled';
import { Box } from '@mui/material';
import FormSelect from '@/components/forms/FormSelect';
import {
  getAvailabilityTypeSelectOptions,
  getPriceTypeSelectOptions,
} from '@/constants';
import FormSkills from '@/components/forms/FormSkills';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';
import { useTranslation } from 'react-i18next';
import CreationFormButtons from '@/components/common/CreationFormButtons';

const JobPostingFourthStep = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<JobCategoryFormInputs>();

  return (
    <FieldsContainer sx={{ gap: '25px' }}>
      <FormInput<JobCategoryFormInputs>
        name="title"
        control={control}
        label={t('form.jobTitle')}
        placeholder={t('form.enterJobTitle')}
      />
      <Box
        sx={{
          display: 'flex',
          gap: { md: '90px', xs: '25px' },
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormSelect<JobCategoryFormInputs>
            name="type"
            control={control}
            label={t('form.jobType')}
            placeholder={t('form.selectJobType')}
            options={getPriceTypeSelectOptions(t)}
          />
        </Box>
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormInput<JobCategoryFormInputs>
            name="budget"
            control={control}
            label={t('form.price')}
            placeholder={t('form.enterPrice')}
            inputProps={{ min: 1, max: 10000000 }}
            type={'number'}
            startIcon={'$'}
            digitsOnly
          />
        </Box>
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormSelect<JobCategoryFormInputs>
            name="availability"
            control={control}
            label={t('form.availability')}
            placeholder={t('form.selectAvailabilityForJob')}
            options={getAvailabilityTypeSelectOptions(t)}
          />
        </Box>
      </Box>
      <Box sx={{ maxWidth: { md: '460px', xs: 'initial' }, width: '100%' }}>
        <LocationAutoSuggest
          placeholder={t('form.locationPlaceholderJob')}
          control={control}
        />
      </Box>
      <FormInput<JobCategoryFormInputs>
        name="description"
        control={control}
        label={t('form.jobDescription')}
        multiline
        rows={4}
        placeholder={t('form.enterJobDescription')}
      />
      <Box sx={{ maxWidth: '550px', width: '100%' }}>
        <FormSkills<JobCategoryFormInputs> name={'skills'} control={control} />
      </Box>
      <CreationFormButtons />
    </FieldsContainer>
  );
};

export default JobPostingFourthStep;

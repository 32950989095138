import { apiRequest } from '@/libs/apiRequest';
import { OfferingFormInputs } from '@/components/common/OfferingCreationForm';
import { OfferingType } from '@/api/offering/types';
import { ExploreJobsParams } from '@/api/explore/types';

export const createOffering = async (
  body: Omit<OfferingFormInputs, 'selectedCategoryId'>
) => {
  const response = await apiRequest<{ message: string }>('/offerings', {
    method: 'POST',
    withAuth: true,
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const editOffering = async ({
  id,
  body,
}: {
  id: string;
  body: Omit<OfferingFormInputs, 'selectedCategoryId'>;
}) => {
  const response = await apiRequest<OfferingType>(`/offerings/${id}`, {
    method: 'PUT',
    withAuth: true,
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchOfferingDetails = async (id: string) => {
  const response = await apiRequest<OfferingType>(`/offerings/${id}`, {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const deleteOffering = async (id: string) => {
  const response = await apiRequest<{ message: string }>(`/offerings/${id}`, {
    method: 'DELETE',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchOfferings = async (params: ExploreJobsParams) => {
  const response = await apiRequest<{
    data: OfferingType[];
    page: number;
    totalPages: number;
  }>('/offerings', {
    method: 'GET',
    withAuth: true,
    params,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const saveOfferingToFavorites = async (id: string) => {
  const response = await apiRequest<{
    id: string;
    isFavorite: boolean;
    jobId: string | null;
    offeringId: string;
    ownerId: string;
    userId: null | string;
  }>(`/offerings/${id}/save`, {
    method: 'POST',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchOfferingsByTalent = async (talentId: string, page = '1') => {
  const response = await apiRequest<{
    data: OfferingType[];
    page: number;
    totalPages: number;
  }>(`/talent/${talentId}/offerings`, {
    method: 'GET',
    withAuth: true,
    params: { page },
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

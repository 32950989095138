import { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

const ProfileWrapper = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Box sx={{ width: '100%', maxWidth: '420px' }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          minHeight: '500px',
          borderRadius: '15px',
          padding: '30px 40px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ProfileWrapper;

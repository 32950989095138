import Layout from '@/components/common/Layout';
import PageBackComponent from '@/components/common/PageBackComponent';
import { Box } from '@mui/material';
import { ProfileContainer } from '@/pages/ProfilePage/components/hirer/HirerProfile.tsx';
import { useParams } from 'react-router-dom';
import JobDetailsSection from '@/pages/JobDetailsPage/components/JobDetailsSection.tsx';
import JobDetailsProfile from '@/pages/JobDetailsPage/components/JobDetailsProfile.tsx';
import { useJobPostingQuery } from '@/api/jobPosting/queries';
import { useTranslation } from 'react-i18next';

const JobDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: job } = useJobPostingQuery(id);
  return (
    <Layout>
      <Box>
        <PageBackComponent title={t('pageTitles.jobDetails')} />
        {job && (
          <ProfileContainer>
            <JobDetailsProfile
              hirer={job.hirer}
              type={job.type}
              location={job.location}
              price={job.budget}
            />
            <JobDetailsSection
              isFavorite={job.isFavorite}
              title={job.title}
              createdAt={job.createdAt}
              id={job.id}
              availability={job.availability}
              description={job.description}
              skills={job.skills}
            />
          </ProfileContainer>
        )}
      </Box>
      {/*SIMILAR JOBS SECTION FOR LATER IMPLEMENTATION =>*/}
      {/*<Box sx={{ marginTop: '52px' }}>*/}
      {/*  <SimilarJobs jobId={id} />*/}
      {/*</Box>*/}
    </Layout>
  );
};

export default JobDetailsPage;

import { toast } from 'sonner';
import { CodeErrorEnum } from '@/constants/errorCodes';
import i18n from '@/i18n';

export const handleMutationError = (error: Error) => {
  try {
    const errorMessage = JSON.parse(error?.message)?.message;
    const errorCode = JSON.parse(error?.message)?.code;
    switch (errorCode) {
      case CodeErrorEnum.INVALID_TOKEN: {
        toast.error(i18n.t('errors.invalidToken'));

        break;
      }
      default: {
        toast.error(errorMessage || i18n.t('errors.unknownError'));
      }
    }
  } catch (e) {
    toast.error(i18n.t('errors.unknownError'));
  }
};

import { talentSchema } from '@/pages/OnboardingPage/components/TalentForm';
import { z } from 'zod';
import EditButton from '@/components/common/EditButton';
import Modal from '@/components/common/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useProfileMutation } from '@/api/user/queries';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserType } from '@/types/user';
import FormGender from '@/components/forms/FormGender';
import FormDate from '@/components/forms/FormDate';
import EditProfileButtons from '@/pages/ProfilePage/components/EditProfileButtons.tsx';
import { useQueryClient } from '@tanstack/react-query';
import FormInput from '@/components/forms/FormInput';
import { useTranslation } from 'react-i18next';
import { FieldsContainer } from '@/styled';
import dayjs from 'dayjs';

export const personalDetailsSchema = talentSchema.pick({
  gender: true,
  firstName: true,
  lastName: true,
  dob: true,
});

export type PersonalDetailsFields = z.infer<typeof personalDetailsSchema>;

type Props = Pick<UserType, 'gender' | 'dob' | 'firstName' | 'lastName'>;

const EditPersonalDetails = ({ firstName, lastName, gender, dob }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const updateProfileMutation = useProfileMutation(queryClient);

  const methods = useForm<PersonalDetailsFields>({
    resolver: zodResolver(personalDetailsSchema),
    defaultValues: {
      gender,
      dob: dayjs(dob).format('YYYY-MM-DD'),
      firstName,
      lastName,
    },
    mode: 'onChange',
  });
  const { reset, handleSubmit, control } = methods;

  const handleClose = () => {
    setOpen(false);
    reset({
      gender,
      dob: dayjs(dob).format('YYYY-MM-DD'),
      firstName,
      lastName,
    });
  };

  const onSubmit = async (data: PersonalDetailsFields) => {
    updateProfileMutation.mutate(data);
    setOpen(false);
  };

  return (
    <>
      <EditButton
        iconSize={12}
        onClick={() => setOpen(true)}
        sx={{ width: '24px', height: '24px', borderRadius: '99px' }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        title={t('form.editPersonalDetails')}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FieldsContainer>
              <FormInput<PersonalDetailsFields>
                name="firstName"
                control={control}
                label={t('form.firstName')}
                placeholder={t('form.enterFirstName')}
              />
              <FormInput<PersonalDetailsFields>
                name="lastName"
                control={control}
                label={t('form.lastName')}
                placeholder={t('form.enterLastName')}
              />
              <FormGender<PersonalDetailsFields>
                name="gender"
                control={control}
                label={t('form.gender')}
              />
              <FormDate<PersonalDetailsFields>
                name="dob"
                disableFuture
                control={control}
                label={t('form.dob')}
              />
              <EditProfileButtons
                isDisabled={updateProfileMutation.isPending}
                handleClose={handleClose}
              />
            </FieldsContainer>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default EditPersonalDetails;

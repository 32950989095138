import { create } from 'zustand';
import { UserType } from '@/types/user';

type State = {
  currentStep: number;
  selectedRole: UserType['role'];
};

type Actions = {
  setCurrentStep: (currentStep: number) => void;
  setPrevNextStep: (step: 1 | -1) => void;
  setSelectedRole: (role: UserType['role']) => void;
};

export const useRegistrationStepsStore = create<State & Actions>((set) => ({
  currentStep: 1,
  selectedRole: 'Talent',
  setCurrentStep: (currentStep) => {
    set(() => ({
      currentStep: currentStep,
    }));
  },
  setPrevNextStep: (step) => {
    set((state) => ({
      currentStep: Math.max(1, state.currentStep + step),
    }));
  },
  setSelectedRole: (role) => {
    set(() => ({
      selectedRole: role,
    }));
  },
}));

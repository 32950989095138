import Layout from '@/components/common/Layout';
import { Outlet, useLocation } from 'react-router-dom';
import { getExploreTabs, PAGE_ROUTES } from '@/constants';
import ExploreNavigation from '@/pages/ExplorePage/components/ExploreNavigation.tsx';
import ExploreJobs from '@/pages/ExplorePage/components/ExploreJobs.tsx';
import { RoutesConfig } from '@/types/common';
import ExploreOfferings from '@/pages/ExplorePage/components/ExploreOfferings.tsx';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ExploreFilter from '@/components/common/ExploreFilter';
import { isHirer, isTalent } from '@/utils/roleCheck';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@/store/authStore';
import ExploreTalents from '@/pages/ExplorePage/components/ExploreTalents.tsx';
import TalentsFilter from '@/pages/ExplorePage/components/TalentsFilter.tsx';

export const exploreRoutesConfig: RoutesConfig = [
  {
    path: `${PAGE_ROUTES.ExploreOfferings}`,
    element: <ExploreOfferings />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted) =>
      isAuth && isEmailVerified && !!isProfileCompleted,
    redirectTo: `/`,
  },
  {
    path: `${PAGE_ROUTES.ExploreJobs}`,
    element: <ExploreJobs />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, role) =>
      isAuth && isEmailVerified && !!isProfileCompleted && isTalent(role),
    redirectTo: `/`,
  },
  {
    path: `${PAGE_ROUTES.ExploreTalents}`,
    element: <ExploreTalents />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted, role) =>
      isAuth && isEmailVerified && !!isProfileCompleted && isHirer(role),
    redirectTo: `/`,
  },
];

const ExplorePage = () => {
  const { t } = useTranslation();
  const role = useAuthStore((state) => state.role);
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  const showTalentFilter = pathname.includes(PAGE_ROUTES.ExploreTalents);

  return (
    <Layout>
      <Grid container spacing={'20px'} sx={{ marginTop: '40px' }}>
        <Grid size={{ xs: 12, md: 9 }}>
          <ExploreNavigation tabs={getExploreTabs(t, role)} />
          <Outlet />
        </Grid>
        {!downMd && (
          <Grid size={{ xs: 12, md: 3 }}>
            {!showTalentFilter && <ExploreFilter />}
            {showTalentFilter && <TalentsFilter />}
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default ExplorePage;

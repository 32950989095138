import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CreationFormButtons = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        gap: '40px',
        marginTop: '40px',
        width: '100%',
      }}
    >
      <Button sx={{ minWidth: '200px' }} variant={'contained'} type={'submit'}>
        {t('form.submit')}
      </Button>
      <Button sx={{ minWidth: '200px' }} variant={'outlined'}>
        {t('form.cancel')}
      </Button>
    </Box>
  );
};

export default CreationFormButtons;

import { useQuery } from '@tanstack/react-query';
import { fetchLocations, verifyEmailToken } from '@/api/auth/fetchers';

export const useLocationsQuery = (searchValue: string) => {
  return useQuery({
    queryKey: ['locations', searchValue],
    queryFn: () => fetchLocations(searchValue),
    enabled: !!searchValue.trim(),
    select: (data) => {
      return data?.map((location: any) => {
        const statePart = location?.state ? `, ${location?.state}` : '';
        const label = `${location?.city}, ${location?.country}${statePart}`;
        return {
          value: location.locationId,
          label,
        };
      });
    },
  });
};

export const useVerifyTokenQuery = (token: string | null) => {
  return useQuery({
    queryKey: ['verify-token', token],
    queryFn: () => verifyEmailToken(token!),
    enabled: !!token,
    retry: 0,
  });
};

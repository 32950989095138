import { UserType } from '@/types/user';
import { TFunction } from 'i18next';

export const getAvailableForLabel = (
  availableFor: UserType['availableFor'] | undefined,
  t: TFunction
) => {
  switch (availableFor) {
    case 'VIDEO': {
      return t('form.videoCall');
    }
    case 'AUDIO': {
      return t('form.audioCall');
    }
    case 'NONE': {
      return t('form.none');
    }
    default: {
      return t('form.none');
    }
  }
};

import FacebookIcon from '@/assets/icons/facebook_icon.svg?react';
import YoutubeIcon from '@/assets/icons/youtube_icon.svg?react';
import LinkedInIcon from '@/assets/icons/linkedin_icon.svg?react';
import WhatsappIcon from '@/assets/icons/whatsapp_icon.svg?react';
import { Button } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  socials: string[];
};

const SocialLinksButtons = ({ socials }: Props) => {
  const style = { width: '25px', height: '25px' };

  const socialIconMapping: {
    [key: string]: { regex: RegExp; icon: ReactNode };
  } = {
    facebook: {
      regex: /facebook\.com/,
      icon: <FacebookIcon style={style} />,
    },
    youtube: {
      regex: /youtube\.com/,
      icon: <YoutubeIcon style={style} />,
    },
    linkedin: {
      regex: /linkedin\.com/,
      icon: <LinkedInIcon style={style} />,
    },
    whatsapp: {
      regex: /wa\.me|whatsapp\.com|^\+?[1-9]\d{1,14}$/, // Match WhatsApp links or phone numbers
      icon: <WhatsappIcon style={style} />,
    },
  };

  // Function to get the correct icon based on the link
  const getIcon = (link: string) => {
    for (const key in socialIconMapping) {
      if (socialIconMapping[key].regex.test(link)) {
        return socialIconMapping[key].icon;
      }
    }
    return null; // Return null if no match is found
  };

  // Function to transform a link
  const transformLink = (link: string) => {
    // If it's already a WhatsApp link, return as-is
    if (/wa\.me|whatsapp\.com/.test(link)) {
      return link;
    }
    // If it's a phone number, convert to WhatsApp link
    if (/^\+?[1-9]\d{1,14}$/.test(link)) {
      return `https://wa.me/${link.replace(/^\+/, '')}`;
    }
    return link; // Return the original link for everything else
  };

  return (
    <>
      {socials.map((link) => {
        const icon = getIcon(link);
        const transformedLink = transformLink(link);

        return (
          icon && (
            <Button
              key={link}
              variant={'contained'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 'initial',
                minHeight: 'initial',
                width: '50px',
                height: '50px',
                borderRadius: '99px',
                backgroundColor: 'gray1',
                padding: 0,
              }}
              onClick={() =>
                window.open(transformedLink, '_blank', 'noopener noreferrer')
              }
            >
              {icon}
            </Button>
          )
        );
      })}
    </>
  );
};

export default SocialLinksButtons;

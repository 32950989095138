import { useEffect, useMemo } from 'react';
import FormInput from '@/components/forms/FormInput';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ExploreFilterFormType } from '@/components/common/ExploreFilter/index.tsx';
import { debounce } from '@mui/material';
import useDebouncedSearch from '@/hooks/useDebouncedSearch';
import { useTranslation } from 'react-i18next';

const PriceFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const priceQuery = searchParams.get('fixedBudget') || '';

  const methods = useFormContext<ExploreFilterFormType>();
  const { control, watch, setValue } = methods;
  const price = watch('fixedBudget');

  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(priceQuery);

  const handleDebouncedValue = useMemo(
    () =>
      debounce(
        (value: string, onDebounce: (value: string) => void) =>
          onDebounce(value),
        500
      ),
    []
  );

  useEffect(() => {
    setValue('fixedBudget', Number(priceQuery));
  }, []);

  useEffect(() => {
    handleDebouncedValue(`${price || ''}`, handleDebounceSearchValue);
  }, [price]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (searchValue) {
      newSearchParams.set('fixedBudget', `${searchValue}`);
      newSearchParams.set('page', '1');
    } else {
      newSearchParams.delete('fixedBudget');
    }
    setSearchParams(newSearchParams);
  }, [searchValue]);
  return (
    <FormInput<ExploreFilterFormType>
      name="fixedBudget"
      control={control}
      label={t('form.price')}
      placeholder={t('form.enterPrice')}
      inputProps={{ min: 0, max: 10000000 }}
      type={'number'}
      startIcon={'$'}
      digitsOnly
    />
  );
};

export default PriceFilter;

import { useQuery } from '@tanstack/react-query';
import {
  fetchOfferingDetails,
  fetchOfferings,
  fetchOfferingsByTalent,
} from '@/api/offering/fetchers';

export const useOfferingDetailsQuery = (id?: string) => {
  return useQuery({
    queryKey: ['offering', id],
    queryFn: () => fetchOfferingDetails(id ?? ''),
    enabled: !!id,
    placeholderData: (prev) => prev,
  });
};

export const useExploreOfferingQuery = (queryParams: {
  categoryId?: string;
  query?: string;
}) => {
  return useQuery({
    queryKey: ['exploreOffering', queryParams],
    queryFn: () => fetchOfferings(queryParams),
  });
};

export const useOfferingsByTalentQuery = ({
  talentId,
  page,
}: {
  talentId?: string;
  page?: string;
}) => {
  return useQuery({
    queryKey: ['exploreOffering', talentId, page],
    queryFn: () => fetchOfferingsByTalent(talentId ?? '', page),
    enabled: !!talentId,
    placeholderData: (prev) => prev,
  });
};

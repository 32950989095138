import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyledSwitch } from '@/styled';
import { useTranslation } from 'react-i18next';
import FormFieldWrapper from '@/components/forms/FormFieldWrapper';

type DatingProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: React.ReactNode;
};
const FormDating = <TSchema extends FieldValues>({
  name,
  label,
  control,
}: DatingProps<TSchema>) => {
  const { t } = useTranslation();
  return (
    <FormFieldWrapper name={name} label={label}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <Typography variant="poppins16Regular">
                {t('form.off')}
              </Typography>
              <StyledSwitch
                id={name}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
                inputProps={{ 'aria-label': t('form.datingToggle') }}
              />
              <Typography variant="poppins16Regular">{t('form.on')}</Typography>
            </Box>
          );
        }}
      />
    </FormFieldWrapper>
  );
};

export default FormDating;

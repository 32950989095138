import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { PhotoFileType, UserType } from '@/types/user.ts';
import { useProfileMutation } from '@/api/user/queries.ts';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormAvatarUpload from '@/components/forms/FormAvatarUpload';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';
import FormInput from '@/components/forms/FormInput';
import FormDating from '@/components/forms/FormDating';
import FormSelect from '@/components/forms/FormSelect';
import { getAvailabilitySelectOptions } from '@/constants';
import EditProfileButtons from '@/pages/ProfilePage/components/EditProfileButtons.tsx';
import { talentSchema } from '@/pages/OnboardingPage/components/TalentForm';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import FormDate from '@/components/forms/FormDate';
import { FieldsContainer } from '@/styled';
import ProfileModalWrapper from '@/pages/ProfilePage/ProfileModalWrapper.tsx';
import dayjs from 'dayjs';
import { firstRegistrationSchemaBase } from '@/pages/RegistrationPage/components/FirstRegistrationStep.tsx';
import { getLocationString } from '@/utils/getLocationString.ts';

export const hirerProfileInfoSchema = talentSchema
  .pick({
    photoId: true,
    dob: true,
    locationId: true,
    locationSearch: true,
    dating: true,
    availableFor: true,
  })
  .merge(firstRegistrationSchemaBase.pick({ displayName: true }));
export type HirerProfileInfoFields = z.infer<typeof hirerProfileInfoSchema>;

type Props = {
  profile: UserType;
};

const EditHirerProfile = ({ profile }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [photoFile, setPhotoFile] = useState<PhotoFileType | null>(
    profile.photo
  );

  const [open, setOpen] = useState(false);
  const updateProfileMutation = useProfileMutation(queryClient);
  const dob = dayjs(profile.dob).format('YYYY-MM-DD');

  const locationSearch = profile.location
    ? getLocationString(profile.location)
    : '';

  const defaultState = {
    photoId: profile.photo?.id,
    locationId: profile.location?.id,
    locationSearch,
    dating: profile.dating,
    availableFor: profile.availableFor || undefined,
    dob,
    ...(profile.displayName && { displayName: profile.displayName }),
  };

  const methods = useForm<HirerProfileInfoFields>({
    resolver: zodResolver(hirerProfileInfoSchema),
    defaultValues: defaultState,
    mode: 'onChange',
  });
  const { reset, handleSubmit, control } = methods;

  const handleClose = () => {
    setOpen(false);
    reset(defaultState);
  };

  const onSubmit = async (data: HirerProfileInfoFields) => {
    const { locationSearch, ...filteredData } = data; // exclude locationSearch
    updateProfileMutation.mutate({ ...filteredData });
    setOpen(false);
  };

  return (
    <ProfileModalWrapper
      handleOpen={() => setOpen(true)}
      open={open}
      handleClose={handleClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldsContainer>
            <FormAvatarUpload<HirerProfileInfoFields>
              name="photoId"
              control={control}
              label={t('form.profilePhoto')}
              photoFile={photoFile}
              onPhotoChange={(photo) => {
                setPhotoFile(photo);
              }}
              horizontal
            />
            <FormInput<HirerProfileInfoFields>
              name="displayName"
              hint={t('validation.displayNameHint')}
              control={control}
              label={t('form.displayName')}
              placeholder={t('form.enterDisplayName')}
            />
            <FormDate<HirerProfileInfoFields>
              name="dob"
              disableFuture
              control={control}
              label={t('form.dob')}
            />
            <LocationAutoSuggest control={control} />
            <FormDating<HirerProfileInfoFields>
              name="dating"
              control={control}
              label={t('form.dating')}
            />
            <FormSelect<HirerProfileInfoFields>
              name="availableFor"
              control={control}
              label={t('form.availableFor')}
              placeholder={t('form.selectAvailability')}
              options={getAvailabilitySelectOptions(t)}
            />
          </FieldsContainer>
          <EditProfileButtons
            isDisabled={updateProfileMutation.isPending}
            handleClose={handleClose}
          />
        </form>
      </FormProvider>
    </ProfileModalWrapper>
  );
};

export default EditHirerProfile;

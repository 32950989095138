import { Box, Typography } from '@mui/material';
import JobFilterBadge from '@/pages/ProfilePage/components/hirer/JobFilterBadge.tsx';
import FavoriteButton from '@/components/common/FavoriteButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { saveJobToFavorites } from '@/api/explore/fetchers';
import { handleMutationError } from '@/utils/handleMutationError';
import GradientCard from '@/components/common/GradientCard';
import { JobDetailsType } from '@/api/jobPosting/types';
import { getFormattedDate } from '@/utils/getFormattedDate';
import { getAvailabilityJobLabel } from '@/utils/getLabels';
import { parseDescription } from '@/utils/parseDescription.tsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = Pick<
  JobDetailsType,
  | 'createdAt'
  | 'availability'
  | 'id'
  | 'title'
  | 'description'
  | 'isFavorite'
  | 'skills'
>;

const JobDetailsSection = memo(
  ({
    createdAt,
    availability,
    id,
    title,
    description,
    isFavorite,
    skills,
  }: Props) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const saveToFavoriteMutation = useMutation({
      mutationFn: saveJobToFavorites,
      onSuccess: (data) => {
        queryClient.setQueryData(['jobPosting', id], (oldData: any) => ({
          ...oldData,
          isFavorite: data?.isFavorite,
        }));
        toast.success(isFavorite ? t('common.removed') : t('common.saved'));
      },
      onError: (error) => {
        handleMutationError(error);
      },
    });
    return (
        <GradientCard>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <JobFilterBadge
            sxTypo={{ fontWeight: 400 }}
            title={getAvailabilityJobLabel(availability, t)}
          />
          <Typography variant={'poppins14Regular'} sx={{ color: '#92979D' }}>
            {getFormattedDate(createdAt)}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ display: 'block', marginTop: '20px' }}
            variant={'poppins20Semibold'}
          >
            {title}
          </Typography>
          <Typography
            sx={{ display: 'block', marginTop: '10px', whiteSpace: 'wrap' }}
            variant={'poppins16Regular'}
          >
            {parseDescription(description)}
          </Typography>
        </Box>
        <Typography
          sx={{ display: 'block', marginTop: '20px' }}
          variant={'poppins20Semibold'}
        >
          {t('form.skills')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          {skills?.map((el) => (
            <JobFilterBadge sxTypo={{ fontWeight: 500 }} key={el} title={el} />
          ))}
        </Box>
        <FavoriteButton
          onClick={() => {
            if (id) {
              saveToFavoriteMutation.mutate(id);
            }
          }}
          isFavorite={isFavorite}
          iconSize={40}
          sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
        />
        </GradientCard>    );
  }
);

export default JobDetailsSection;

import { Box, Typography } from '@mui/material';
import JobPostedItem from '@/pages/ProfilePage/components/hirer/JobPostedItem.tsx';
import { useUserProfile } from '@/api/user/queries';
import { useAuthStore } from '@/store/authStore';
import { JobDetailsType } from '@/api/jobPosting/types';
import { useTranslation } from 'react-i18next';

type Props = {
  jobs: JobDetailsType[];
};

const JobPostedList = ({ jobs }: Props) => {
  const { t } = useTranslation();
  const isAuth = useAuthStore((state) => state.isAuth);
  const { data: profile } = useUserProfile(isAuth);
  const author = {
    src: profile?.photo?.url ?? '',
    name: profile?.displayName ?? '',
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant={'poppins16Semibold'}>
        {t('jobPosting.myPostedJobs')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '25px',
          marginTop: '20px',
        }}
      >
        {jobs?.map((el) => (
          <JobPostedItem key={el.id} job={el} author={author} />
        ))}
      </Box>
    </Box>
  );
};

export default JobPostedList;

import { Avatar, Box, Typography } from '@mui/material';
import { stringAvatar } from '@/utils/getAvatarString.ts';
import FavoriteButton from '@/components/common/FavoriteButton';
import { useTranslation } from 'react-i18next';
import { getLocationString } from '@/utils/getLocationString.ts';
import { getDatingLocationLabel } from '@/utils/getDatingLocationLabel.ts';
import JobFilterBadge from '@/pages/ProfilePage/components/hirer/JobFilterBadge.tsx';
import { toast } from 'sonner';
import { handleMutationError } from '@/utils/handleMutationError.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveTalentToFavorites } from '@/api/talents/fetchers.ts';
import { TalentType } from '@/api/talents/types.ts';
import { StyledTruncatedTypography } from '@/styled';
import { PAGE_ROUTES } from '@/constants';
import { useNavigate } from 'react-router-dom';

type Props = {
  talent: TalentType;
};

type ExploreTalentsDataType = {
  data: TalentType[];
  [key: string]: any;
};

const ExploreTalentListItem = ({ talent }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userName = talent.displayName ?? '';
  const location = talent?.location ? getLocationString(talent?.location) : '';

  const queryClient = useQueryClient();
  const saveToFavoriteMutation = useMutation({
    mutationFn: saveTalentToFavorites,
    onSuccess: (data) => {
      const queries = queryClient.getQueriesData({ queryKey: ['talents'] });

      // Helper function to update the query data
      const updateQueryData = (oldData: ExploreTalentsDataType) => {
        if (!oldData?.data) return oldData;

        const updatedData = oldData.data.map((item) =>
          item.id === talent.id
            ? { ...item, isFavorite: data?.isFavorite }
            : item
        );

        return { ...oldData, data: updatedData };
      };

      // Update each query
      queries.forEach(([queryKey]) => {
        queryClient.setQueryData(queryKey, (oldData) =>
          updateQueryData(oldData as ExploreTalentsDataType)
        );
      });

      // Show success toast
      const message = data?.isFavorite
        ? t('common.saved')
        : t('common.removed');
      toast.success(message);
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  return (
    <Box
      onClick={() => {
        navigate(`/${PAGE_ROUTES.Talent}/${talent.id}`);
      }}
      sx={{
        cursor: 'pointer',
        borderRadius: '10px',
        padding: '20px',
        background: (theme) => theme.palette.gradient7,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: '15px', overflow: 'hidden' }}>
          <Avatar src={talent?.photo?.url} {...stringAvatar(userName, 60)} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '3px',
              overflow: 'hidden',
            }}
          >
            <StyledTruncatedTypography
              sx={{ whiteSpace: 'normal' }}
              variant={'poppins24Medium'}
            >
              {userName}
            </StyledTruncatedTypography>
            <StyledTruncatedTypography
              variant={'poppins16Regular'}
              sx={{ color: '#92979D', whiteSpace: 'normal' }}
            >
              {talent.position}
            </StyledTruncatedTypography>
          </Box>
        </Box>
        <FavoriteButton
          reversedColor
          iconSize={40}
          onClick={(e) => {
            e.stopPropagation();
            saveToFavoriteMutation.mutate(talent.id);
          }}
          isFavorite={talent.isFavorite}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '50px',
          marginTop: '25px',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
            overflow: 'hidden',
          }}
        >
          <Typography variant={'poppins16Regular'} sx={{ color: '#92979D' }}>
            {t('form.location')}
          </Typography>
          <StyledTruncatedTypography
            sx={{ whiteSpace: 'normal' }}
            variant={'poppins18Medium'}
          >
            {location}
          </StyledTruncatedTypography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <Typography variant={'poppins16Regular'} sx={{ color: '#92979D' }}>
            {t('form.dating')}
          </Typography>
          <Typography variant={'poppins18Medium'}>
            {talent.dating ? t('form.preferred') : t('form.notPreferred')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <Typography variant={'poppins16Regular'} sx={{ color: '#92979D' }}>
            {t('form.accepts')}
          </Typography>
          <Typography variant={'poppins18Medium'}>
            {getDatingLocationLabel(talent.datingLocation, t)}
          </Typography>
        </Box>
      </Box>
      {!!talent.subCategories.length && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            marginTop: '25px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ color: '#92979D', marginRight: '20px' }}
            variant={'poppins14Regular'}
          >
            {t('common.services')}
          </Typography>
          {talent.subCategories.map((el) => (
            <JobFilterBadge
              sxTypo={{ fontWeight: 500, fontSize: '16px' }}
              key={el.id}
              title={el.name}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ExploreTalentListItem;

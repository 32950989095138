import { Button, ButtonProps } from '@mui/material';
import FavoriteIcon from '@/assets/icons/heart_icon.svg?react';
import FavoriteFilledIcon from '@/assets/icons/heart_filled_icon.svg?react';

const FavoriteButton = ({
  isFavorite,
  iconSize = 50,
  reversedColor = false,
  ...props
}: ButtonProps & {
  reversedColor?: boolean;
  iconSize?: number;
  isFavorite: boolean;
}) => {
  return (
    <Button
      variant={'contained'}
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'initial',
        minWidth: iconSize,
        height: iconSize,
        borderRadius: '99px',
        padding: 0,
        ...((reversedColor || isFavorite) && { backgroundColor: 'gray1' }),
        ...props?.sx,
      }}
    >
      {isFavorite ? (
        <FavoriteFilledIcon />
      ) : (
        <FavoriteIcon style={{ color: !reversedColor ? '#fff' : '#4D63F5' }} />
      )}
    </Button>
  );
};

export default FavoriteButton;

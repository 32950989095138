import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useTalentsQuery } from '@/api/talents/queries.ts';
import ExploreSearchWrapper from '@/pages/ExplorePage/components/ExploreSearchWrapper.tsx';
import ExploreOfferingsSearch from '@/pages/ExplorePage/components/ExploreOfferingsSearch.tsx';
import Grid from '@mui/material/Grid2';
import { StyledProgressLoader } from '@/styled';
import { Box, Typography } from '@mui/material';
import ExploreJobsPagination from '@/pages/ExplorePage/components/ExploreJobsPagination.tsx';
import ExploreTalentListItem from '@/components/common/ExploreTalentListItem';
import { TalentType } from '@/api/talents/types.ts';

export type ExploreTalentsDataType = {
  data: TalentType[];
  page: number;
  totalPages: number;
}

const ExploreTalents = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [cachedData, setCachedData] = useState<ExploreTalentsDataType>({ data: [], page: 1, totalPages: 1 });

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams]);
  const { data, isLoading } = useTalentsQuery(queryParams);

  const talents = data?.data;

  const cachedTalents = cachedData?.data;
  const totalPages = cachedData?.totalPages || 1;

  useEffect(() => {
    if (talents && !isLoading) {
      setCachedData(data);
    }
  }, [talents, isLoading]);

  const noJobsFound = !cachedTalents?.length && !isLoading;
  return (
    <>
      <ExploreSearchWrapper searchComponent={<ExploreOfferingsSearch />} />
      <Grid sx={{ position: 'relative' }} container spacing={2}>
        {isLoading && <StyledProgressLoader />}
        {cachedTalents?.map((talent) => (
          <Grid size={{ xs: 12 }} key={talent.id}>
            <ExploreTalentListItem talent={talent} />
          </Grid>
        ))}
        {noJobsFound && !isLoading && (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <Typography
              sx={{ color: 'purple1', textAlign: 'center', display: 'block' }}
              variant={'poppins20Semibold'}
            >
              {t('common.noFiltersFound', {
                item: t('common.talents'),
              })}
            </Typography>
          </Box>
        )}
        {!!cachedTalents.length && <ExploreJobsPagination count={totalPages} />}
      </Grid>
    </>
  );
};

export default ExploreTalents;

import { apiRequest } from '@/libs/apiRequest';
import { ExploreJobsParams } from '@/api/explore/types';
import { JobListItemType } from '@/api/jobPosting/types';

export const fetchExploreJobs = async (params: ExploreJobsParams) => {
  const response = await apiRequest<{
    data: JobListItemType[];
    page: number;
    totalPages: number;
  }>('/jobs', {
    method: 'GET',
    withAuth: true,
    params,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchSimilarJobs = async (id: string) => {
  const response = await apiRequest<any[]>(`/jobs/${id}/similar`, {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const saveJobToFavorites = async (id: string) => {
  const response = await apiRequest<any>(`/jobs/${id}/save`, {
    method: 'POST',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

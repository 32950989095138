import Grid from '@mui/material/Grid2';
import ExploreJobListItem from '@/components/common/ExploreJobListItem';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useExploreJobsQuery } from '@/api/explore/queries';
import ExploreJobsPagination from '@/pages/ExplorePage/components/ExploreJobsPagination.tsx';
import { Box, Typography } from '@mui/material';
import { JobListItemType } from '@/api/jobPosting/types';
import ExploreSearchWrapper from '@/pages/ExplorePage/components/ExploreSearchWrapper.tsx';
import ExploreOfferingsSearch from '@/pages/ExplorePage/components/ExploreOfferingsSearch.tsx';
import { StyledProgressLoader } from '@/styled';
import { useTranslation } from 'react-i18next';

const ExploreJobs = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [cachedData, setCachedData] = useState<{
    data: JobListItemType[];
    page: number;
    totalPages: number;
  }>({ data: [], page: 1, totalPages: 1 });

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams]);
  const { data, isLoading } = useExploreJobsQuery(queryParams);

  const jobs = data?.data;
  const cachedJobs = cachedData?.data;
  const totalPages = cachedData?.totalPages || 1;

  useEffect(() => {
    if (jobs && !isLoading) {
      setCachedData(data);
    }
  }, [jobs, isLoading]);

  const noJobsFound = !cachedJobs?.length && !isLoading;
  return (
    <>
      <ExploreSearchWrapper searchComponent={<ExploreOfferingsSearch />} />
      <Grid sx={{ position: 'relative' }} container spacing={2}>
        {isLoading && <StyledProgressLoader />}
        {cachedJobs?.map((job) => (
          <Grid size={{ xs: 12, sm: 6, lg: 4 }} key={job.id}>
            <ExploreJobListItem job={job} />
          </Grid>
        ))}
        {noJobsFound && !isLoading && (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <Typography
              sx={{ color: 'purple1', textAlign: 'center', display: 'block' }}
              variant={'poppins20Semibold'}
            >
              {t('common.noFiltersFound', {
                item: t('common.jobs'),
              })}
            </Typography>
          </Box>
        )}
        {!!cachedJobs.length && <ExploreJobsPagination count={totalPages} />}
      </Grid>
    </>
  );
};

export default ExploreJobs;

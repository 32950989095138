import { useQuery } from '@tanstack/react-query';
import { fetchExploreJobs } from '@/api/explore/fetchers';

export const useExploreJobsQuery = (queryParams: {
  categoryId?: string;
  query?: string;
}) => {
  return useQuery({
    queryKey: ['exploreJobs', queryParams],
    queryFn: () => fetchExploreJobs(queryParams),
  });
};


import { Box } from '@mui/material';
import HeaderLogo from '@/assets/icons/header_logo.svg?react';

const EmptyImage = () => {
  return (
    <Box
      sx={{
        minHeight: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <HeaderLogo />
    </Box>
  );
};

export default EmptyImage;

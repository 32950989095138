import validator from 'validator';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '@/components/forms/FormInput';
import { FieldsContainer } from '@/styled';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from '@/api/auth/fetchers';
import { handleMutationError } from '@/utils/handleMutationError';
import { toast } from 'sonner';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import {useTranslation} from "react-i18next";
import i18n, {z} from '@/i18n';

export const forgotPasswordSchema = z.object({
  email: z
      .string()
      .min(1, { message: i18n.t('validation.emailRequired') })
      .refine((value) => validator.isEmail(value), {
        message: i18n.t('validation.emailInvalid'),
      }),
});

export type ForgotPasswordField = z.infer<typeof forgotPasswordSchema>;

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const forgotPasswordMutation = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      toast.success(t('forgotPassword.successMessage'));
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  const methods = useForm<ForgotPasswordField>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = (data: ForgotPasswordField) => {
    forgotPasswordMutation.mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <FormInput<ForgotPasswordField>
            name="email"
            control={control}
            label={t('form.email')}
            type="email"
            placeholder={t('form.enterEmail')}
          />
          <Box
            sx={{
              marginTop: '100px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                !isDirty || !isValid || forgotPasswordMutation.isPending
              }
              type={'submit'}
            >
              {t('forgotPassword.requestResetButton')}
            </Button>
            <Button
              onClick={() => {
                navigate(`/${PAGE_ROUTES.Login}`);
              }}
              variant="text"
              color="primary"
            >
              {t('forgotPassword.loginButton')}
            </Button>
          </Box>
        </FieldsContainer>
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';
import { Box } from '@mui/material';

const LocationFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useFormContext();
  const { control, watch, setValue } = methods;
  // Watch for locationId and locationSearch changes
  const locationId = watch('locationId');
  const locationSearch = watch('locationSearch');

  // Initialize form values from URL params
  useEffect(() => {
    setValue('locationId', searchParams.get('locationId') || '');
    setValue('locationSearch', searchParams.get('locationSearch') || '');
  }, []);

  // Update URL params when form values change
  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (locationId) {
      newSearchParams.set('locationId', locationId);
      newSearchParams.set('locationSearch', locationSearch || '');
      newSearchParams.set('page', '1');
    } else {
      newSearchParams.delete('locationId');
      newSearchParams.delete('locationSearch');
    }

    if (!locationSearch) {
      newSearchParams.delete('locationId');
      newSearchParams.delete('locationSearch');
    }
    setSearchParams(newSearchParams);
  }, [locationId, locationSearch]);

  return (
    <Box sx={{ marginTop: '25px' }}>
      <LocationAutoSuggest control={control} />
    </Box>
  );
};

export default LocationFilter;

import { Box, Button } from '@mui/material';
import JobPostingFilterBlock from '@/components/common/JobPostingForm/JobPostingFilterBlock.tsx';
import { useFormContext } from 'react-hook-form';
import { SubcategoryType } from '@/types/filters';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';
import { useCreationFormStepsStore } from '@/store/formCreationStepsStore';
import { useTranslation } from 'react-i18next';

const JobPostingThirdStep = ({
  subcategories,
}: {
  subcategories: SubcategoryType[];
}) => {
    const { t } = useTranslation();
    const setPrevNextStep = useCreationFormStepsStore(
    (state) => state.setPrevNextStep
  );
  const { watch } = useFormContext<JobCategoryFormInputs>();
  const subCategoryId = watch('subCategoryId');
  const selectedCategory = subcategories?.find((el) => el.id === subCategoryId);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginTop: '40px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {selectedCategory?.filters?.map((filter) => {
        return (
          <JobPostingFilterBlock
            key={filter?.id}
            name={'data'}
            filter={filter}
          />
        );
      })}
      <Button
        onClick={() => {
          setPrevNextStep(1);
        }}
        variant={'contained'}
        sx={{ width: '100%', maxWidth: '460px' }}
      >
        {t('form.next')}
      </Button>
    </Box>
  );
};

export default JobPostingThirdStep;

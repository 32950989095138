import {
  Box,
  Button,
  Drawer,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import HeaderLogo from '@/assets/icons/header_logo.svg?react';
import BurgerMenuIcon from '@/assets/icons/burger_menu_icon.svg?react';
import { useNavigate } from 'react-router-dom';
import {
  getHirerHeaderNavigation,
  getTalentHeaderNavigation,
} from '@/constants';
import LanguageSelector from '@/components/common/LanugageSelector';
import { useTranslation } from 'react-i18next';
import AvatarDropdown from '@/components/common/Header/AvatarDropdown.tsx';
import { useState } from 'react';
import { useAuthStore } from '@/store/authStore';
import { isHirer, isTalent } from '@/utils/roleCheck';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '20px 70px',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 20px',
  },
}));

const Header = () => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const talentNavigations = getTalentHeaderNavigation(t);
  const role = useAuthStore((state) => state.role);
  const hirerNavigations = getHirerHeaderNavigation(t);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const NavigationList = (
    <>
      {isTalent(role) &&
        talentNavigations.map((el) => (
          <Button key={el.text} onClick={() => navigate(el.linkTo)}>
            {el.text}
          </Button>
        ))}

      {isHirer(role) &&
        hirerNavigations.map((el) => (
          <Button key={el.text} onClick={() => navigate(el.linkTo)}>
            {el.text}
          </Button>
        ))}
      <LanguageSelector />
    </>
  );

  const BurgerMenu = (
    <>
      <IconButton
        sx={{ height: '60px', padding: 0, color: 'purple1', maxWidth: '50px' }}
        onClick={toggleDrawer(true)}
      >
        <BurgerMenuIcon />
      </IconButton>
      <Drawer
        anchor={'right'}
        PaperProps={{
          sx: {
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          },
        }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        {NavigationList}
      </Drawer>
    </>
  );

  return (
    <StyledHeader>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          navigate('/');
        }}
      >
        <HeaderLogo />
      </Box>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        {downMd && <AvatarDropdown />}
        {!downMd ? NavigationList : BurgerMenu}
        {!downMd && <AvatarDropdown />}
      </Box>
    </StyledHeader>
  );
};

export default Header;

import { BASE_URL, BasicResponse } from '@/types/common';
import {
  MultipleFilesUploadResponse,
  SingleFileUploadResponse,
  UpdateProfileBody,
} from '@/api/user/types';
import { apiRequest } from '@/libs/apiRequest';
import {PositionType, UserType} from '@/types/user';

export const updateProfile = async (body: UpdateProfileBody) => {
  const response = await apiRequest<BasicResponse<any>>('/profile', {
    method: 'POST',
    body,
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const uploadFile = async (body: FormData) => {
  const response = await apiRequest<SingleFileUploadResponse>('/file', {
    method: 'POST',
    body,
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const uploadFiles = async (
  files: File[]
): Promise<MultipleFilesUploadResponse> => {
  const formData = new FormData();
  files.forEach((file) => formData.append('files', file));

  const response = await fetch(`${BASE_URL}/files`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to upload files');
  }

  return response.json();
};

export const getMe = async (redirect?: () => void) => {
  const response = await apiRequest<UserType>('/me', {
    method: 'GET',
    withAuth: true,
    redirect,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
export const fetchPositions = async (searchValue: string) => {
  const response = await apiRequest<PositionType[]>('/positions', {
    method: 'GET',
    params: { q: searchValue },
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

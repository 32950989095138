import PageBackComponent from '@/components/common/PageBackComponent';
import OfferingCreationForm, {
  OfferingFormInputs,
} from '@/components/common/OfferingCreationForm';
import Layout from '@/components/common/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOfferingDetailsQuery } from '@/api/offering/queries';
import { toast } from 'sonner';
import { PAGE_ROUTES } from '@/constants';
import { handleMutationError } from '@/utils/handleMutationError';
import { editOffering } from '@/api/offering/fetchers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCreationFormStepsStore } from '@/store/formCreationStepsStore';

const OfferingEditPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: offering } = useOfferingDetailsQuery(id);
  const setCurrentStep = useCreationFormStepsStore(
    (state) => state.setCurrentStep
  );

  const editOfferingMutation = useMutation({
    mutationFn: editOffering,
    onSuccess: (data) => {
      toast.success(t('form.offeringUpdated'));
      queryClient.invalidateQueries({ queryKey: ['offering', data?.id] });
      navigate(`/${PAGE_ROUTES.OfferingDetails}/${id}`);
      setCurrentStep(1);
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  const onSubmit = (data: OfferingFormInputs) => {
    const { selectedCategoryId, ...filteredData } = data;
    if (id) {
      editOfferingMutation.mutate({ id, body: filteredData });
    }
  };

  return (
    <Layout>
      <PageBackComponent title={t('pageTitles.editYourOffering')} />
      <OfferingCreationForm offering={offering} onSubmit={onSubmit} />
    </Layout>
  );
};

export default OfferingEditPage;

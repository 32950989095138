import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import validator from 'validator';
import SecondTalentStep from '@/pages/OnboardingPage/components/TalentForm/SecondTalentStep.tsx';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore';
import { Box, Button } from '@mui/material';
import ThirdTalentStep from '@/pages/OnboardingPage/components/TalentForm/ThirdTalentStep.tsx';
import FourthTalentStep from '@/pages/OnboardingPage/components/TalentForm/FourthTalentStep.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProfile } from '@/api/user/fetchers';
import * as Sentry from '@sentry/react';
import { PhotoFileType } from '@/types/user';
import { useState } from 'react';
import { handleMutationError } from '@/utils/handleMutationError';
import { useNavigate } from 'react-router-dom';
import {
  AVAILABILITY_TYPE,
  DATING_LOCATION,
  SOCIAL_MEDIA_REGEXP,
} from '@/constants';
import { useTranslation } from 'react-i18next';
import i18n, { z } from '@/i18n';

const socialLinksSchema = z
  .array(z.string().optional())
  .superRefine((values, ctx) => {
    values.forEach((value, index) => {
      if (value && !Object.values(SOCIAL_MEDIA_REGEXP)[index]?.test(value)) {
        ctx.addIssue({
          path: [index], // Specify the exact index of the invalid value
          message: i18n.t('validation.invalidSocialMedia'),
          code: 'custom'
        });
      }
    });
  }).optional();

export const talentSchema = z.object({
  firstName: z.string().min(2, {
    message: i18n.t('validation.minLength', {
      count: 1,
    }),
  }),
  lastName: z.string().min(2, {
    message: i18n.t('validation.minLength', {
      count: 1,
    }),
  }),
  gender: z.enum(['Male', 'Female', 'Other']),
  dob: z
    .string()
    .min(1, {
      message: i18n.t('validation.required'),
    })
    .refine(
      (value) => (!value ? true : validator.isISO8601(value, { strict: true })),
      {
        message: i18n.t('validation.invalidFormat'),
      }
    ),
  position: z.string().optional(),
  skills: z.array(z.string()).optional(),
  bio: z.string().optional(),
  socialLinks: socialLinksSchema,
  dating: z.boolean().optional(),
  datingLocation: z.enum(DATING_LOCATION).optional(),
  availableFor: z.enum(AVAILABILITY_TYPE).optional(),
  videoUrl: z
    .string()
    .optional()
    .refine(
      (value) =>
        !value
          ? true
          : validator.isURL(value || '', { require_protocol: true }),
      {
        message: i18n.t('validation.invalidURL'),
      }
    ),
  photoId: z.string().optional(),
  locationId: z.string().min(1, {
    message: i18n.t('validation.required'),
  }),
  locationSearch: z.string().optional(),
  mediaIds: z.array(z.string()).optional(),
});

export type TalentFields = z.infer<typeof talentSchema>;

const TalentForm = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [photoFile, setPhotoFile] = useState<PhotoFileType | null>(null);
  const [mediaFiles, setMediaFiles] = useState<PhotoFileType[]>([]);

  const updateProfileMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      queryClient.setQueryData(['userProfile'], (oldData: any) => ({
        ...oldData,
        completed: true,
      }));
      navigate(`/`);
    },
    onError: (error) => {
      if (error?.message !== 'Invalid data') {
        Sentry.captureException(error);
      }
      handleMutationError(error);
    },
  });

  const currentStep = useRegistrationStepsStore((state) => state.currentStep);
  const setPrevNextStep = useRegistrationStepsStore(
    (state) => state.setPrevNextStep
  );
  const methods = useForm<TalentFields>({
    resolver: zodResolver(talentSchema),
    defaultValues: {
      dob: '',
      gender: 'Male',
      dating: false,
      locationSearch: '',
    },
    // reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const onSubmit = async (data: TalentFields) => {
    const valid = await isStepValid();
    if (valid && currentStep !== 3) {
      setPrevNextStep(1);
      return;
    }

    if (valid && currentStep === 3) {
      const { locationSearch, ...filteredData } = data; // exclude locationSearch
      updateProfileMutation.mutate({ ...filteredData, completed: true });
    }
  };
  const { handleSubmit, trigger } = methods;

  const isStepValid = async () => {
    if (currentStep === 1) {
      return await trigger([
        'firstName',
        'lastName',
        'gender',
        'dob',
        'locationId',
      ]);
    }

    return true;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 1 && (
          <SecondTalentStep
            photoFile={photoFile}
            onPhotoChange={(file) => {
              setPhotoFile(file);
            }}
          />
        )}
        {currentStep === 2 && <ThirdTalentStep />}
        {currentStep === 3 && (
          <FourthTalentStep
            mediaFiles={mediaFiles}
            onMediaChange={(mediaFiles) => {
              setMediaFiles(mediaFiles);
            }}
          />
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '12px',
            marginTop: '100px',
            flexDirection: 'column',
          }}
        >
          {currentStep > 1 && (
            <Button
              sx={{ flex: 1 }}
              onClick={() => setPrevNextStep(-1)}
              variant={'outlined'}
            >
              {t('form.back')}
            </Button>
          )}
          <Button
            disabled={updateProfileMutation.isPending}
            sx={{ flex: 1 }}
            variant={'contained'}
            type={'submit'}
          >
            {currentStep === 3 ? t('form.submit') : t('form.next')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default TalentForm;

import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  isDisabled: boolean;
  handleClose: () => void;
};

const EditProfileButtons = ({ isDisabled, handleClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: '20px',
      }}
    >
      <Button
        disabled={isDisabled}
        sx={{ flex: 1 }}
        variant={'contained'}
        type={'submit'}
      >
        {t('common.done')}
      </Button>
      <Button sx={{ flex: 1 }} onClick={handleClose} variant={'outlined'}>
        {t('form.cancel')}
      </Button>
    </Box>
  );
};

export default EditProfileButtons;

import { useRef } from 'react';
import env from '@/env';
import validator from 'validator';
import { useMutation } from '@tanstack/react-query';
import { loginUser } from '@/api/auth/fetchers';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore';
import { UserType } from '@/types/user';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldsContainer } from '@/styled';
import LoginAsField from '@/components/forms/LoginAsField';
import FormInput from '@/components/forms/FormInput';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Link } from '@mui/material';
import { useAuthStore } from '@/store/authStore';
import { handleMutationError } from '@/utils/handleMutationError';
import { PAGE_ROUTES } from '@/constants';
import { useTranslation } from 'react-i18next';
import i18n, { z } from '@/i18n';

const siteKey = env.VITE_GCAPTHA_SITE_KEY;

export const loginSchema = z.object({
  role: z.enum(['Talent', 'Hirer']),
  email: z
    .string()
    .min(1, { message: i18n.t('validation.emailRequired') })
    .refine((value) => validator.isEmail(value), {
      message: i18n.t('validation.emailInvalid'),
    }),
  password: z
    .string()
    .min(8, { message: i18n.t('validation.passwordMinLength') }),
  gRecaptchaResponse: z
    .string()
    .min(1, { message: i18n.t('validation.recaptchaRequired') }),
});

export type LoginFormFields = z.infer<typeof loginSchema>;

const LoginForm = () => {
  const { t } = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const setToken = useAuthStore((state) => state.setToken);
  const setVerificationEmail = useAuthStore(
    (state) => state.setVerificationEmail
  );

  const methods = useForm<LoginFormFields>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      role: 'Talent',
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = methods;

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data, variables) => {
      if (data?.accessToken) {
        setToken(data.accessToken);
        setVerificationEmail(variables?.email);
      }
    },
    onError: (error) => {
      handleMutationError(error);
      // Reset ReCAPTCHA on error
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setValue('gRecaptchaResponse', '');
      }
    },
  });

  const setSelectedRole = useRegistrationStepsStore(
    (state) => state.setSelectedRole
  );

  const onRoleChange = (role: UserType['role']) => {
    setValue('role', role);
    setSelectedRole(role);
  };

  const role = watch('role');

  const onSubmit = (data: LoginFormFields) => {
    loginMutation.mutate(data);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <LoginAsField value={role} onChange={onRoleChange} />
          <FormInput<LoginFormFields>
            name="email"
            control={control}
            label={t('form.email')}
            type="email"
            placeholder={t('form.enterEmail')}
          />
          <FormInput<LoginFormFields>
            name="password"
            control={control}
            label={t('form.password')}
            type="password"
            placeholder={t('form.enterPassword')}
          />
          <Controller
            name={'gRecaptchaResponse'}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={(value) => onChange(value)}
                />
              );
            }}
          />
          <Link
            href={`/${PAGE_ROUTES.ForgotPassword}`}
            sx={{ cursor: 'pointer', color: 'purple1', marginTop: '16px' }}
          >
            {t('forgotPassword.title')}
          </Link>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '100px' }}
            disabled={!isDirty || !isValid || loginMutation.isPending}
            type={'submit'}
          >
            {t('form.login')}
          </Button>
        </FieldsContainer>
      </form>
    </FormProvider>
  );
};

export default LoginForm;

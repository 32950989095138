import { Box, FormControl, styled, Typography } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { StyledInput } from '@/styled';
import { ReactNode, useRef, useState } from 'react';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowDownIcon from '@/assets/icons/arrow_down.svg?react';
import dayjs from 'dayjs';
import { StyledFormLabel } from '@/components/forms/FormLabel';

type FormDateProps<TSchema extends FieldValues> = DatePickerProps<any> & {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: ReactNode;
};

const BoxIcon = styled(Box)(() => ({
  position: 'absolute',
  right: '20px',
  top: '50%',
  transform: 'translateY(-100%)',
  display: 'flex',
  cursor: 'pointer',
}));

const FormDate = <TSchema extends FieldValues>({
  name,
  label,
  control,
  disableFuture = false,
  ...props
}: FormDateProps<TSchema>) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLElement | null>(null);

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <StyledFormLabel onClick={() => setOpen(true)} htmlFor={name}>
        {label}
      </StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Box sx={{ position: 'relative', width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: '100%' }}
                  open={open}
                  onClose={() => setOpen(false)}
                  value={value ? dayjs(value) : null}
                  maxDate={disableFuture ? dayjs() : undefined}
                  onChange={(date) =>
                    onChange(date ? dayjs(date).format('YYYY-MM-DD') : '')
                  }
                  slots={{
                    textField: StyledInput,
                  }}
                  slotProps={{
                    textField: {
                      onClick: () => setOpen(true),
                      inputRef,
                    },
                    popper: {
                      anchorEl: inputRef.current,
                    },
                  }}
                  {...props}
                />
              </LocalizationProvider>
              <BoxIcon onClick={() => setOpen(true)}>
                <ArrowDownIcon />
              </BoxIcon>
              <Typography
                variant={'error'}
                sx={{ opacity: error?.message ? 1 : 0 }}
              >
                {error?.message ?? 'helper text'}
              </Typography>
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormDate;

import { talentSchema } from '@/pages/OnboardingPage/components/TalentForm';
import { z } from 'zod';
import { UserType } from '@/types/user';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useProfileMutation } from '@/api/user/queries';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import EditButton from '@/components/common/EditButton';
import Modal from '@/components/common/Modal';
import EditProfileButtons from '@/pages/ProfilePage/components/EditProfileButtons.tsx';
import FormInput from '@/components/forms/FormInput';
import { FieldsContainer } from '@/styled';
import { useTranslation } from 'react-i18next';

export const aboutInfoSchema = talentSchema.pick({
  bio: true,
});

export type AboutInfoField = z.infer<typeof aboutInfoSchema>;

type Props = Pick<UserType, 'bio'>;

const EditAboutInfo = ({ bio }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const updateProfileMutation = useProfileMutation(queryClient);

  const methods = useForm<AboutInfoField>({
    resolver: zodResolver(aboutInfoSchema),
    defaultValues: { bio },
    mode: 'onChange',
  });
  const { reset, handleSubmit, control } = methods;

  const handleClose = () => {
    setOpen(false);
    reset({ bio });
  };

  const onSubmit = async (data: AboutInfoField) => {
    updateProfileMutation.mutate(data);
    setOpen(false);
  };

  return (
    <>
      <EditButton
        iconSize={12}
        onClick={() => setOpen(true)}
        sx={{ width: '24px', height: '24px', borderRadius: '99px' }}
      />
      <Modal open={open} onClose={handleClose} title={t('form.editAboutInfo')}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FieldsContainer>
              <FormInput<AboutInfoField>
                name="bio"
                control={control}
                label={t('form.about')}
                multiline
                rows={4}
                placeholder={t('form.enterAboutYourself')}
              />
              <EditProfileButtons
                isDisabled={updateProfileMutation.isPending}
                handleClose={handleClose}
              />
            </FieldsContainer>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default EditAboutInfo;

import { create } from 'zustand';

type State = {
  currentStep: number;
};

type Actions = {
  setCurrentStep: (currentStep: number) => void;
  setPrevNextStep: (step: number) => void;
};

export const useCreationFormStepsStore = create<State & Actions>((set) => ({
  currentStep: 1,
  setCurrentStep: (currentStep) => {
    set(() => ({
      currentStep: currentStep,
    }));
  },
  setPrevNextStep: (step) => {
    set((state) => ({
      currentStep: Math.max(1, state.currentStep + step),
    }));
  },
}));

import { FieldsContainer } from '@/styled';
import { Box } from '@mui/material';
import FormInput from '@/components/forms/FormInput';
import { TalentFields } from '@/pages/OnboardingPage/components/TalentForm/index.tsx';
import { useFormContext } from 'react-hook-form';
import FormAvatarUpload from '@/components/forms/FormAvatarUpload';
import FormGender from '@/components/forms/FormGender';
import FormDate from '@/components/forms/FormDate';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';
import { PhotoFileType } from '@/types/user';
import { useTranslation } from 'react-i18next';

type Props = {
  photoFile: PhotoFileType | null;
  onPhotoChange: (photoFile: PhotoFileType) => void;
};

const SecondTalentStep = ({ photoFile, onPhotoChange }: Props) => {
  const { control } = useFormContext<TalentFields>();
  const { t } = useTranslation();
  return (
    <FieldsContainer>
      <FormAvatarUpload<TalentFields>
        name="photoId"
        control={control}
        label={t('form.profilePhoto')}
        photoFile={photoFile}
        onPhotoChange={onPhotoChange}
        horizontal
      />
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          marginTop: '20px',
          flexWrap: 'wrap',
        }}
      >
        <FormInput<TalentFields>
          name="firstName"
          control={control}
          label={t('form.firstName')}
          placeholder={t('form.enterFirstName')}
        />
        <FormInput<TalentFields>
          name="lastName"
          control={control}
          label={t('form.lastName')}
          placeholder={t('form.enterLastName')}
        />
      </Box>
      <FormGender<TalentFields>
        name="gender"
        control={control}
        label={t('form.gender')}
      />
      <FormDate<TalentFields>
        name="dob"
        disableFuture
        control={control}
        label={t('form.dob')}
      />
      <LocationAutoSuggest control={control} />
    </FieldsContainer>
  );
};

export default SecondTalentStep;

import {
  Box,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  InputBase,
  Menu,
  Radio,
  RadioProps,
  styled,
  Switch,
  SwitchProps,
  TextField,
  Typography,
} from '@mui/material';

export const FormCardBox = styled(Box)(({ theme }) => ({
  background: theme.palette.gradient3,
  padding: '50px',
  borderRadius: '30px',
  marginTop: '70px',
  maxWidth: '560px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'initial',
    marginTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '25px',
  },
}));
export const FieldsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  marginTop: '25px',
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  outline: `2px solid ${theme.palette.blue1}`,
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.purple1,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundImage: 'radial-gradient(#fff,#fff 45%,transparent 0%)',
    content: '""',
  },
}));

export function BpRadio(props: Readonly<RadioProps>) {
  return (
    <Radio
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export const StyledInput = styled(InputBase)<{
  active?: number;
  error?: boolean;
  password?: number;
  iconstart?: number;
}>(({ theme, active, error, password, iconstart }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: 0,
    backgroundColor: '#fff',
    borderColor: error
      ? theme.palette.red1
      : active
        ? theme.palette.purple1
        : theme.palette.gray1,
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    borderRadius: '12px',
    boxShadow: '0px 0px 6px 0px rgba(183, 189, 201, 1) inset',
    padding: `18px ${password ? '47px' : '20px'} 18px ${iconstart ? '64px' : '20px'}`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.purple1,
      borderRadius: '12px',
    },
  },
}));

export const StyledTextField = styled(TextField)<{
  active?: number;
}>(({ theme, active }) => ({
  '& .MuiInput-underline:after': {
    opacity: '0',
  },
  '& fieldset': {
    border: 0,
    outline: 0,
  },
  '& .MuiInputBase-root': {
    position: 'relative',
    border: 0,
    outline: 0,
    backgroundColor: '#fff',
    borderColor: active ? theme.palette.purple1 : theme.palette.gray1,
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    borderRadius: '12px',
    boxShadow: '0px 0px 6px 0px rgba(183, 189, 201, 1) inset',
    padding: `11px  20px`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      border: 0,
      outline: 0,
      borderColor: theme.palette.purple1,
      borderRadius: '12px',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiInputLabel-root': {
    marginTop: theme.spacing(3),
  },
}));

export const StyledLandingTextField = styled(StyledTextField)<{
  isopen?: number;
  active?: number;
}>(({ theme, active, isopen }) => ({
  '& .MuiInput-underline:after': {
    opacity: '0',
  },
  '& fieldset': {
    border: 0,
    outline: 0,
  },
  '& .MuiInputBase-root': {
    position: 'relative',
    border: 0,
    outline: 0,
    backgroundColor: '#fff',
    borderColor: active ? theme.palette.purple1 : theme.palette.gray1,
    fontSize: 32,
    borderTopLeftRadius: isopen ? 50 : 100,
    borderTopRightRadius: isopen ? 50 : 100,
    borderBottomLeftRadius: isopen ? 0 : 100,
    borderBottomRightRadius: isopen ? 0 : 100,
    fontWeight: 400,
    color: '#000',
    padding: '25px 30px 25px 80px',
    boxShadow: isopen ? 'none' : '0px 0px 6px 0px rgba(183, 189, 201, 1) inset',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      border: 0,
      outline: 0,
      borderColor: theme.palette.purple1,
      borderRadius: '12px',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiInputLabel-root': {
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiInputBase-root': {
      padding: '15px 100px 15px 70px',
      paddingRight: '70px !important',
      fontSize: 24,
    },
  },
}));

export const StyledTruncatedTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledCheckbox = styled((props: CheckboxProps) => (
  <Checkbox
    icon={
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="18"
          height="18"
          rx="4.5"
          stroke="#DAE6F4"
        />
      </svg>
    }
    checkedIcon={
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="18"
          height="18"
          rx="4.5"
          fill="#4D63F5"
          stroke="#4D63F5"
        />
        <path
          d="M4.5 9.49865L7.83459 12.8332L14.5 6.16406"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
    {...props}
  />
))(() => ({
  padding: 0,
}));

export const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px 5px',
    transitionDuration: '300ms',
    color: theme.palette.purple1,
    '& + .MuiSwitch-track': {
      border: '1px solid',
      borderRadius: '14px',
      borderColor: theme.palette.purple1,
    },
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.purple1,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.purple1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#fff',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const StyledLandingSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 150,
  height: 74,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: '2px 5px',
    padding: '10px',
    transitionDuration: '300ms',
    color: theme.palette.purple1,
    '&  .MuiSwitch-input': {
      width: '400%',
    },
    '& + .MuiSwitch-track': {
      border: '2px solid',
      borderRadius: '40px',
      borderColor: '#fff',
    },
    '&.Mui-checked': {
      transform: 'translateX(70px)',
      color: theme.palette.purple1,
      '& + .MuiSwitch-track': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.purple1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 47,
    height: 47,
    border: '2px solid',
    borderColor: '#fff',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'transparent',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 50,
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 35,
      height: 35,
      border: '2px solid',
      borderColor: '#fff',
    },
    '&  .MuiSwitch-input': {
      width: '300%',
    },
    '& .MuiSwitch-switchBase': {
      padding: '5px',
      '&.Mui-checked': {
        transform: 'translateX(45px)',
      },
    },
  },
}));

export const StyledDivider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: theme.palette.gray2,
}));

export const StyledProgressLoader = styled(() => (
  <Box
    sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
  >
    <CircularProgress />
  </Box>
))();

export const StyledOfferingsTextField = styled(StyledTextField)<{}>(() => ({
  '& .MuiInputBase-root': {
    paddingRight: '85px',
  },
}));

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    minWidth: '225px',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 0px 12px 0px rgba(0,0,0,0.12)',
  },
}));

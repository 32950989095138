import {AVAILABILITY_JOB_TYPE, JOB_TYPE, OFFERING_TYPE} from '@/constants';
import { TFunction } from 'i18next';

export const getJobTypeLabel = (
  type: (typeof JOB_TYPE)[number],
  t: TFunction
) => {
  switch (type) {
    case 'WEEKLY': {
      return t('form.weekly');
    }
    case 'FIXED': {
      return t('form.fixed');
    }
    case 'HOURLY': {
      return t('form.hourly');
    }
    default:
      return '';
  }
};

export const getAvailabilityJobLabel = (
  availability: (typeof AVAILABILITY_JOB_TYPE)[number],
  t: TFunction
) => {
  switch (availability) {
    case 'CONTRACT': {
      return t('form.contract');
    }
    case 'FREELANCE': {
      return t('form.freelance');
    }
    case 'FULL_TIME': {
      return t('form.fullTime');
    }
    case 'PART_TIME': {
      return t('form.partTime');
    }
    default:
      return '';
  }
};

export const getOfferingTypeLabel = (
    type: (typeof OFFERING_TYPE)[number],
    t: TFunction
) => {
  switch (type) {
    case 'FIXED': {
      return t('form.fixed');
    }
    case 'HOURLY': {
      return t('form.hourly');
    }
    default:
      return '';
  }
};

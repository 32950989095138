import { StyledFormLabel } from '@/components/forms/FormLabel';
import React, { ReactNode } from 'react';
import { Box, FormControl } from '@mui/material';

type Props = {
  label?: React.ReactNode;
  name: string;
  children: ReactNode;
};

const FormFieldWrapper = ({ label, name, children }: Props) => {
  return (
    <FormControl
      component="fieldset"
      sx={{ width: '100%', marginBottom: '20px' }}
    >
      <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
        <StyledFormLabel htmlFor={name} horizontal>
          {label}
        </StyledFormLabel>
        {children}
      </Box>
    </FormControl>
  );
};

export default FormFieldWrapper;

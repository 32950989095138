import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCategoriesQuery,
  useSubcategoriesQuery,
} from '@/api/jobPosting/queries';
import CategoriesStep from '@/components/common/JobPostingForm/CategoriesStep.tsx';
import SubcategoriesStep from '@/components/common/JobPostingForm/SubcategoriesStep.tsx';
import JobPostingThirdStep from '@/components/common/JobPostingForm/JobPostingThirdStep.tsx';
import JobPostingFourthStep from '@/components/common/JobPostingForm/JobPostingFourthStep.tsx';
import { useEffect, useMemo } from 'react';
import { getLocationString } from '@/utils/getLocationString';
import { AVAILABILITY_JOB_TYPE, JOB_TYPE } from '@/constants';
import { JobDetailsType } from '@/api/jobPosting/types';
import { useCreationFormStepsStore } from '@/store/formCreationStepsStore';
import i18n, { z } from '@/i18n';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SubcategoryFiltersType = z.object({
  filterId: z.string(),
  filterValueId: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
    })
  ),
});

const jobCategorySchema = z.object({
  selectedCategoryId: z.string().min(1, {
    message: i18n.t('validation.youMustSelectACategory'),
  }),
  locationId: z.string().min(1, {
    message: i18n.t('validation.required'),
  }),
  locationSearch: z.string().min(1, {
    message: i18n.t('validation.required'),
  }),
  subCategoryId: z.string(),
  title: z.string().min(1),
  description: z.string().min(1),
  type: z.enum(JOB_TYPE),
  data: z.array(SubcategoryFiltersType).optional(),
  budget: z.coerce
    .number()
    .min(1, { message: i18n.t('validation.required', { min: 1 }) }),
  skills: z.array(z.string()).min(1, {
    message: i18n.t('validation.required'),
  }),
  availability: z.enum(AVAILABILITY_JOB_TYPE),
});

export type JobCategoryFormInputs = z.infer<typeof jobCategorySchema>;

type Props = {
  onSubmit: (data: JobCategoryFormInputs) => void;
  job?: JobDetailsType;
};

const JobPostingForm = ({ onSubmit, job }: Props) => {
  const { t } = useTranslation();
  const currentStep = useCreationFormStepsStore((state) => state.currentStep);
  const setPrevNextStep = useCreationFormStepsStore(
    (state) => state.setPrevNextStep
  );
  const methods = useForm<JobCategoryFormInputs>({
    resolver: zodResolver(jobCategorySchema),
    defaultValues: {
      selectedCategoryId: '',
      subCategoryId: '',
      type: 'FIXED',
      availability: 'CONTRACT',
      budget: 1,
    },
  });

  const { watch, handleSubmit, reset } = methods;
  const selectedCategoryId = watch('selectedCategoryId');
  const subCategoryId = watch('subCategoryId');

  const { data: categories } = useCategoriesQuery();
  const { data: subcategories } = useSubcategoriesQuery(selectedCategoryId);

  const selectedCategory = useMemo(() => {
    return subcategories?.find((el) => el.id === subCategoryId);
  }, [subcategories, subCategoryId]);

  const onNextSubcategoryButton = () => {
    if (selectedCategory?.filters?.length) {
      setPrevNextStep(1);
    } else {
      // SKIP ONE STEP IF NO FILTERS
      setPrevNextStep(2);
    }
  };

  useEffect(() => {
    if (job?.id) {
      reset({
        title: job?.title,
        description: job?.description,
        type: job?.type,
        budget: job?.budget,
        skills: job?.skills,
        availability: job?.availability,
        subCategoryId: job?.subCategory?.id,
        selectedCategoryId: job?.subCategory?.categoryId,
        locationId: job?.location?.id || '',
        locationSearch: getLocationString(job?.location),
        data: [
          ...(job.data || []).map((el) => ({
            filterId: el.id,
            name: el.name,
            filterValueId: [...(el.values || [])],
          })),
        ],
      });
    }
  }, [job?.id]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 1 && (
          <>
            {' '}
            <Typography
              sx={{ color: 'purple1', marginY: '30px', display: 'block' }}
              variant={'poppins30Semibold'}
            >
              {t('jobPosting.title')}
            </Typography>
            <CategoriesStep categories={categories || []} />
          </>
        )}
        {currentStep === 2 && (
          <SubcategoriesStep
            onNextButton={onNextSubcategoryButton}
            subcategories={subcategories || []}
          />
        )}
        {currentStep === 3 && (
          <JobPostingThirdStep subcategories={subcategories || []} />
        )}
        {currentStep === 4 && <JobPostingFourthStep />}
      </form>
    </FormProvider>
  );
};

export default JobPostingForm;

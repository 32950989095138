import GradientCard from '@/components/common/GradientCard';
import TalentPersonalDetails from '@/pages/ProfilePage/components/talent/TalentPersonalDetails.tsx';
import { UserType } from '@/types/user';
import AboutInfo from '@/pages/ProfilePage/components/talent/AboutInfo.tsx';
import { StyledDivider } from '@/styled';
import MediaInfo from '@/pages/ProfilePage/components/talent/MediaInfo.tsx';
import ProfileSkills from '@/pages/ProfilePage/components/talent/ProfileSkills.tsx';
import MoreOfferings from '@/pages/OfferingDetailsPage/components/MoreOfferings.tsx';
import { Box } from '@mui/material';

type Props = {
  profile: UserType;
  isMyProfile: boolean;
};

const TalentProfileDetailsSection = ({ profile, isMyProfile }: Props) => {
  return (
    <GradientCard>
      <TalentPersonalDetails
        isMyProfile={isMyProfile}
        lastName={profile.lastName}
        dob={profile.dob}
        firstName={profile.firstName}
        gender={profile.gender}
      />
      <StyledDivider sx={{ marginY: '15px' }} />
      <AboutInfo bio={profile.bio} isMyProfile={isMyProfile} />
      <StyledDivider sx={{ marginY: '15px' }} />
      <MediaInfo
        videoUrl={profile.videoUrl}
        medias={profile.medias}
        isMyProfile={isMyProfile}
      />
      <StyledDivider sx={{ marginY: '15px' }} />
      <ProfileSkills skills={profile.skills} isMyProfile={isMyProfile} />
      <Box sx={{ marginTop: '44px' }}>
        <MoreOfferings talentId={profile.id} isMyOfferings={isMyProfile}/>
      </Box>
    </GradientCard>
  );
};

export default TalentProfileDetailsSection;

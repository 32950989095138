export function resizeImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const mimeType = file.type;

    if (mimeType === 'image/gif') {
      resolve(file); // Return the original file for now, as GIF resizing isn't handled by canvas
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const maxDimension = 1000;

      // Calculate new dimensions while preserving the aspect ratio
      let width = img.width;
      let height = img.height;

      if (width > height && width > maxDimension) {
        height = (height / width) * maxDimension;
        width = maxDimension;
      } else if (height > maxDimension) {
        width = (width / height) * maxDimension;
        height = maxDimension;
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);

        const outputMimeType =
          mimeType === 'image/png' ? 'image/png' : 'image/jpeg';
        const quality = mimeType === 'image/png' ? undefined : 0.8; // PNG ignores quality

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(new File([blob], file.name, { type: outputMimeType }));
            } else {
              reject(new Error('Failed to create blob from canvas.'));
            }
          },
          outputMimeType,
          quality
        );
      }

      URL.revokeObjectURL(img.src);
    };

    img.onerror = () => {
      reject(new Error('Failed to load the image.'));
    };
  });
}

import { useState } from 'react';
import { PhotoFileType, UserType } from '@/types/user';
import { z } from 'zod';
import { talentSchema } from '@/pages/OnboardingPage/components/TalentForm';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import FormInput from '@/components/forms/FormInput';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';
import FormSelect from '@/components/forms/FormSelect';
import FormSocials from '@/components/forms/FormSocialLinks';
import FormDating from '@/components/forms/FormDating';
import FormDatingLocation from '@/components/forms/FormDatingLocation';
import { useProfileMutation } from '@/api/user/queries';
import EditProfileButtons from '@/pages/ProfilePage/components/EditProfileButtons.tsx';
import FormAvatarUpload from '@/components/forms/FormAvatarUpload';
import { getAvailabilitySelectOptions } from '@/constants';
import { useTranslation } from 'react-i18next';
import ProfileModalWrapper from '@/pages/ProfilePage/ProfileModalWrapper.tsx';
import { firstRegistrationSchemaBase } from '@/pages/RegistrationPage/components/FirstRegistrationStep.tsx';
import { getLocationString } from '@/utils/getLocationString.ts';

export const profileInfoSchema = talentSchema
  .pick({
    photoId: true,
    locationId: true,
    locationSearch: true,
    position: true,
    dating: true,
    datingLocation: true,
    availableFor: true,
    socialLinks: true,
  })
  .merge(firstRegistrationSchemaBase.pick({ displayName: true }));

export type ProfileInfoFields = z.infer<typeof profileInfoSchema>;

type Props = {
  profile: UserType;
};

const EditProfileInfo = ({ profile }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [photoFile, setPhotoFile] = useState<PhotoFileType | null>(
    profile.photo
  );

  const [open, setOpen] = useState(false);
  const updateProfileMutation = useProfileMutation(queryClient);

  const defaultState = {
    photoId: profile.photo?.id,
    locationId: profile.location?.id,
    locationSearch: profile.location ? getLocationString(profile.location) : '',
    position: profile.position || '',
    dating: profile.dating,
    datingLocation: profile.datingLocation || undefined,
    availableFor: profile.availableFor || undefined,
    socialLinks: profile.socialLinks || [],
    ...(profile.displayName && { displayName: profile.displayName }),
  };

  const methods = useForm<ProfileInfoFields>({
    resolver: zodResolver(profileInfoSchema),
    defaultValues: defaultState,
    mode: 'onChange',
  });
  const { reset, handleSubmit, control } = methods;

  const handleClose = () => {
    setOpen(false);
    reset(defaultState);
  };

  const onSubmit = async (data: ProfileInfoFields) => {
    const { locationSearch, ...filteredData } = data; // exclude locationSearch
    updateProfileMutation.mutate({ ...filteredData });
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    reset(defaultState);
  };

  return (
    <ProfileModalWrapper
      handleOpen={handleOpen}
      open={open}
      handleClose={handleClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormAvatarUpload<ProfileInfoFields>
            name="photoId"
            control={control}
            label={t('form.profilePhoto')}
            photoFile={photoFile}
            onPhotoChange={(photo) => {
              setPhotoFile(photo);
            }}
            horizontal
          />
          <FormInput<ProfileInfoFields>
            name="displayName"
            hint={t('validation.displayNameHint')}
            control={control}
            label={t('form.displayName')}
            placeholder={t('form.enterDisplayName')}
          />
          <LocationAutoSuggest control={control} />
          <FormInput<ProfileInfoFields>
            name="position"
            control={control}
            label={t('form.currentPosition')}
            placeholder={t('form.enterCurrentPosition')}
          />
          <FormDating<ProfileInfoFields>
            name="dating"
            control={control}
            label={t('form.dating')}
          />
          <FormDatingLocation<ProfileInfoFields>
            name="datingLocation"
            control={control}
            label={t('form.location')}
          />
          <FormSelect<ProfileInfoFields>
            name="availableFor"
            control={control}
            label={t('form.availableFor')}
            placeholder={t('form.selectAvailability')}
            options={getAvailabilitySelectOptions(t)}
          />
          <FormSocials<ProfileInfoFields>
            name="socialLinks"
            control={control}
            label={t('form.socials')}
          />
          <EditProfileButtons
            isDisabled={updateProfileMutation.isPending}
            handleClose={handleClose}
          />
        </form>
      </FormProvider>
    </ProfileModalWrapper>
  );
};

export default EditProfileInfo;

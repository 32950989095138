import { RegistrationRequestBody } from '@/api/auth/types';
import { apiRequest } from '@/libs/apiRequest';

export const registerUser = async (body: RegistrationRequestBody) => {
  const response = await apiRequest<{ accessToken: string }>('/register', {
    method: 'POST',
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const loginUser = async (body: Omit<RegistrationRequestBody, 'displayName'>) => {
  const response = await apiRequest<{ accessToken: string }>('/login', {
    method: 'POST',
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const forgotPassword = async (body: { email: string }) => {
  const response = await apiRequest<{ message: string }>('/forgot-password', {
    method: 'POST',
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const resetPassword = async (body: {
  newPassword: string;
  token: string;
}) => {
  const response = await apiRequest<{ message: string }>('/reset-password', {
    method: 'POST',
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchLocations = async (searchValue: string) => {
  const response = await apiRequest<any>('/location', {
    method: 'GET',
    params: { q: searchValue },
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const verifyEmailToken = async (token: string) => {
  const response = await apiRequest<{ accessToken: string; message: string }>(
    '/verify-email',
    {
      method: 'GET',
      params: { t: token },
    }
  );

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const resendEmailLink = async () => {
  const response = await apiRequest<any>('/resend-verification', {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

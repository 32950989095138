import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form';
import { Box, FormControl, styled, Typography } from '@mui/material';
import { StyledInput } from '@/styled';
import CheckCircle from '@/assets/icons/check_circle.svg?react';
import React from 'react';
import FacebookIcon from '@/assets/icons/facebook_icon.svg?react';
import WhatsappIcon from '@/assets/icons/whatsapp_icon.svg?react';
import YoutubeIcon from '@/assets/icons/youtube_icon.svg?react';
import LinkedinIcon from '@/assets/icons/linkedin_icon.svg?react';
import { StyledFormLabel } from '@/components/forms/FormLabel';
import { useTranslation } from 'react-i18next';
import { SOCIAL_MEDIA_REGEXP } from '@/constants';

export const BoxIcon = styled(Box)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
});

type SocialsProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: React.ReactNode;
};

const FormSocials = <TSchema extends FieldValues>({
  name,
  label,
  control,
}: SocialsProps<TSchema>) => {
  const { t } = useTranslation();
  const socialMediaPlatforms = [
    {
      label: 'Facebook',
      placeholder: t('form.enterFacebookLink'),
      regex: SOCIAL_MEDIA_REGEXP.facebook,
      icon: <FacebookIcon />,
    },
    {
      label: 'WhatsApp',
      placeholder: t('form.enterWhatsAppLink'),
      regex: SOCIAL_MEDIA_REGEXP.whatsapp,
      icon: <WhatsappIcon />,
    },
    {
      label: 'YouTube',
      placeholder: t('form.enterYouTubeLink'),
      regex: SOCIAL_MEDIA_REGEXP.youtube,
      icon: <YoutubeIcon />,
    },
    {
      label: 'LinkedIn',
      placeholder: t('form.enterLinkedInLink'),
      regex: SOCIAL_MEDIA_REGEXP.linkedin,
      icon: <LinkedinIcon />,
    },
  ];
  return (
    <FormControl
      component="fieldset"
      sx={{ width: '100%', marginBottom: '20px' }}
    >
      <StyledFormLabel htmlFor={name}>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const socialLinks = Array.isArray(value)
            ? [...Array(4)].map((_, i) => value[i] || '')
            : ['', '', '', ''];
          const handleInputChange =
            (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
              const updatedLinks = [...socialLinks];
              updatedLinks[index] = e.target.value;
              onChange(updatedLinks);
            };

          const typedError = error as unknown as FieldError[];

          return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {socialMediaPlatforms.map((platform, index) => {
                return (
                  <Box
                    key={platform.label}
                    sx={{
                      position: 'relative',
                      width: '100%',
                      marginBottom: '10px',
                    }}
                  >
                    <FormControl sx={{ width: '100%', position: 'relative' }}>
                      <StyledInput
                        onChange={handleInputChange(index)}
                        value={socialLinks[index]}
                        placeholder={platform.placeholder}
                        fullWidth
                        inputProps={{
                          style: { paddingLeft: '58px', paddingRight: '55px' },
                        }}
                      />
                      <BoxIcon sx={{ left: '26px' }}>{platform.icon}</BoxIcon>
                      <>
                        {socialLinks[index] &&
                        platform.regex.test(socialLinks[index]) ? (
                          <BoxIcon sx={{ right: '20px' }}>
                            <CheckCircle />
                          </BoxIcon>
                        ) : null}
                      </>
                    </FormControl>
                    {typedError && (
                      <Typography variant={'error'}>
                        {typedError?.[index]?.message}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormSocials;

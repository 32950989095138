import AuthLayout from '@/components/common/AuthLayout';
import { FormCardBox } from '@/styled';
import { Typography } from '@mui/material';
import ResetPasswordForm from '@/pages/ResetPasswordPage/components/ResetPasswordForm.tsx';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <FormCardBox sx={{ marginTop: '100px' }}>
        <Typography
          sx={{ textAlign: 'center', display: 'block', marginBottom: '40px' }}
          variant={'poppins30Semibold'}
        >
          {t('resetPassword.title')}
        </Typography>
        <ResetPasswordForm />
      </FormCardBox>
    </AuthLayout>
  );
};

export default ResetPasswordPage;

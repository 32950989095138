import EditButton from '@/components/common/EditButton';
import Modal from '@/components/common/Modal';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '@mui/material';

type Props = {
  children: ModalProps['children'];
  handleOpen: () => void;
  open: boolean;
  handleClose: () => void;
};
const ProfileModalWrapper = ({
  open,
  handleOpen,
  handleClose,
  children,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <EditButton
        onClick={handleOpen}
        iconSize={22}
        sx={{ width: '48px', height: '48px', borderRadius: '99px' }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        title={t('form.editProfileInfo')}
      >
        {children}
      </Modal>
    </>
  );
};

export default ProfileModalWrapper;

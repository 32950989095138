import { ReactNode } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import MobileExploreJobFilter from '@/pages/ExplorePage/components/MobileExploreJobFilter.tsx';

const ExploreSearchWrapper = ({
  searchComponent,
}: {
  searchComponent: ReactNode;
}) => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        width: '100%',
        marginY: '40px',
        ...(downMd && { display: 'flex', gap: '20px' }),
      }}
    >
      {searchComponent}
      {downMd && <MobileExploreJobFilter />}
    </Box>
  );
};

export default ExploreSearchWrapper;

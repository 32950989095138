import { Box, Button, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';

const ExploreNavigation = ({
  sx,
  tabs,
}: {
  sx?: SxProps;
  tabs: { navigateTo: string; label: string }[];
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const handleTabClick = (navigateTo: string) => navigate(navigateTo);
  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '530px',
        width: '100%',
        borderBottom: (theme) => `1px solid ${theme.palette.purple1}`,
        ...sx,
      }}
    >
      {tabs.map(({ label, navigateTo }) => (
        <NavLink
          style={{ width: '100%' }}
          key={label}
          to={navigateTo}
          end={true}
        >
          {({ isActive }) => (
            <Button
              onClick={() => handleTabClick(navigateTo)}
              variant={'text'}
              sx={{
                width: '100%',
                borderRadius: 0,
                whiteSpace: 'nowrap',
                ...(downSm && { fontSize: '16px' }),
                ...(isActive
                  ? {
                      color: 'purple1',
                    }
                  : { color: '#051320', fontWeight: 400 }),
              }}
              key={`${label}`}
            >
              {label}
              {isActive && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    backgroundColor: 'purple1',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    height: '6px',
                    width: '100%',
                  }}
                />
              )}
            </Button>
          )}
        </NavLink>
      ))}
    </Box>
  );
};

export default ExploreNavigation;

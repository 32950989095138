import { Box, Typography } from '@mui/material';
import JobFilterBadge from '@/pages/ProfilePage/components/hirer/JobFilterBadge.tsx';
import { StyledDivider, StyledTruncatedTypography } from '@/styled';
import AvatarNameLocation from '@/components/common/AvatarNameLocation';
import DollarSignIcon from '@/assets/icons/dolar_sign_icon.svg?react';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import { truncateString } from '@/utils/truncateString';
import { JobListItemType } from '@/api/jobPosting/types';
import { getLocationString } from '@/utils/getLocationString';
import { getAvailabilityJobLabel, getJobTypeLabel } from '@/utils/getLabels';
import { getFormattedDate } from '@/utils/getFormattedDate';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/formatCurrency';

const ExploreJobListItem = ({ job }: { job: JobListItemType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const truncatedDescription = truncateString(job?.description, 98);

  const userName = job.hirer.displayName ?? ''
  const location = getLocationString(job?.location);
  const formattedDate = getFormattedDate(job?.createdAt);
  const jobTypeLabel = getJobTypeLabel(job.type, t);
  const budgetLabel = job.budget ? `: ${formatCurrency(job.budget)}$` : '';
  return (
    <Box
      onClick={() => {
        navigate(`/${PAGE_ROUTES.JobDetails}/${job.id}`);
      }}
      sx={{
        cursor: 'pointer',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '15px',
        width: '100%',
        minHeight: '270px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <JobFilterBadge
          sxTypo={{ fontWeight: 400 }}
          title={getAvailabilityJobLabel(job.availability, t)}
          key={job.id}
        />
        <Typography variant={'poppins14Regular'} sx={{ color: '#92979D' }}>
          {formattedDate}
        </Typography>
      </Box>
      <Box>
        <StyledTruncatedTypography
          sx={{ display: 'block', marginTop: '20px' }}
          variant={'poppins16Medium'}
        >
          {job.title}
        </StyledTruncatedTypography>
        <StyledTruncatedTypography
          sx={{ marginTop: '10px', whiteSpace: 'wrap' }}
          variant={'poppins14Regular'}
        >
          {truncatedDescription}
        </StyledTruncatedTypography>
      </Box>
      <StyledDivider sx={{ marginY: '20px' }} />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'end',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <AvatarNameLocation
          src={job.hirer.photo?.url || ''}
          name={userName}
          location={location}
          locationSx={{ color: 'purple3', fontWeight: 700 }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: '3px',
            alignItems: 'center',
            color: 'purple3',
          }}
        >
          <DollarSignIcon />
          <Typography
            variant={'poppins13Bold'}
            sx={{ color: 'purple3', whiteSpace: 'nowrap' }}
          >
            {`${jobTypeLabel}${budgetLabel}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreJobListItem;

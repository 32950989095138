import { Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
};

const ExploreFilterSection = ({ title, children }: Props) => {
  return (
    <>
      <Typography variant="poppins16Medium">{title}</Typography>
      {children}
    </>
  );
};

export default ExploreFilterSection;

import PageBackComponent from '@/components/common/PageBackComponent';
import Layout from '@/components/common/Layout';
import OfferingCreationForm, {
  OfferingFormInputs,
} from '@/components/common/OfferingCreationForm';
import { useNavigate } from 'react-router-dom';
import FormCreationStepper from '@/components/common/FormCreationStepper';
import { useCreationFormStepsStore } from '@/store/formCreationStepsStore';
import { useMutation } from '@tanstack/react-query';
import { handleMutationError } from '@/utils/handleMutationError';
import { useEffect, useState } from 'react';
import CreationConfirmationModal from '@/components/common/CreationConfirmationModal';
import { createOffering } from '@/api/offering/fetchers';
import { useTranslation } from 'react-i18next';

const OfferingCreationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentStep = useCreationFormStepsStore((state) => state.currentStep);
  const setCurrentStep = useCreationFormStepsStore(
    (state) => state.setCurrentStep
  );
  const setPrevNextStep = useCreationFormStepsStore(
    (state) => state.setPrevNextStep
  );
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleClose = () => {
    setOpenConfirmation(false);
  };
  const createOfferingMutation = useMutation({
    mutationFn: createOffering,
    onSuccess: () => {
      setOpenConfirmation(true);
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate(-1);
    } else {
      setPrevNextStep(-1);
    }
  };
  const onSubmit = (data: OfferingFormInputs) => {
    const { selectedCategoryId, ...filteredData } = data;
    createOfferingMutation.mutate(filteredData);
  };

  useEffect(() => {
    return () => {
      setCurrentStep(1);
    };
  }, []);
  return (
    <Layout>
      <PageBackComponent
        onBackButton={handleBackButton}
        title={t('pageTitles.listYourOffering')}
      />
      <FormCreationStepper currentStep={currentStep} stepsAmount={3} />
      <OfferingCreationForm onSubmit={onSubmit} />
      <CreationConfirmationModal
        openConfirmation={openConfirmation}
        handleClose={handleClose}
        title={t('offeringCreation.successTitle')}
        congratulationText={t('offeringCreation.successMessage')}
        info={t('offeringCreation.successSubMessage')}
      />
    </Layout>
  );
};

export default OfferingCreationPage;

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserType } from '@/types/user';

export type AuthState = {
  token: string | null;
  isAuth: boolean;
  verificationEmail: string | null;
  role: UserType['role'] | null;
  isProfileCompleted: boolean;
  isEmailVerified: boolean;
  landingSearchValue: string;
};

type Actions = {
  setToken: (token: string) => void;
  logout: () => void;
  setVerificationEmail: (email: string) => void;
  setRole: (role: UserType['role'] | null) => void;
  setProfileState: (profileState: {
    isProfileCompleted?: boolean;
    isEmailVerified?: boolean;
  }) => void;
  setLandingSearchValue: (value: string) => void;
};

const initialState: AuthState = {
  token: null,
  isAuth: false,
  role: null,
  verificationEmail: null,
  isProfileCompleted: false,
  isEmailVerified: false,
  landingSearchValue: '',
};

export const useAuthStore = create(
  persist<AuthState & Actions>(
    (set) => ({
      ...initialState,
      setToken: (token) =>
        set(() => ({
          token,
          isAuth: !!token,
        })),
      logout: () => set(() => initialState),
      setVerificationEmail: (verificationEmail) =>
        set(() => ({
          verificationEmail,
        })),
      setRole: (role) =>
        set(() => ({
          role,
        })),
      setProfileState: (profileState) =>
        set((state) => ({
          isProfileCompleted:
            profileState.isProfileCompleted ?? state.isProfileCompleted,
          isEmailVerified:
            profileState.isEmailVerified ?? state.isEmailVerified,
        })),
      setLandingSearchValue: (value) => {
        set(() => ({
          landingSearchValue: value,
        }));
      },
    }),
    {
      name: 'auth',
    }
  )
);

import Layout from '@/components/common/Layout';
import PageBackComponent from '@/components/common/PageBackComponent';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import JobPostingForm, {
  JobCategoryFormInputs,
} from '@/components/common/JobPostingForm';
import { useMutation } from '@tanstack/react-query';
import { createJobPosting } from '@/api/jobPosting/fetchers';
import { handleMutationError } from '@/utils/handleMutationError';
import FormCreationStepper from '@/components/common/FormCreationStepper';
import { useCreationFormStepsStore } from '@/store/formCreationStepsStore';
import CreationConfirmationModal from '@/components/common/CreationConfirmationModal';
import { useTranslation } from 'react-i18next';

const JobPostingPage = () => {
  const { t } = useTranslation();

  const createJobPostingMutation = useMutation({
    mutationFn: createJobPosting,
    onSuccess: () => {
      setOpenConfirmation(true);
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleClose = () => {
    setOpenConfirmation(false);
  };
  const navigate = useNavigate();
  const currentStep = useCreationFormStepsStore((state) => state.currentStep);
  const setCurrentStep = useCreationFormStepsStore(
    (state) => state.setCurrentStep
  );
  const setPrevNextStep = useCreationFormStepsStore(
    (state) => state.setPrevNextStep
  );

  const onSubmit = (data: JobCategoryFormInputs) => {
    const { selectedCategoryId, locationSearch, ...filteredData } = data;
    const filters = filteredData?.data;
    const transformedData = {
      ...filteredData,
      data: [
        ...(filters || []).map((el) => ({
          ...el,
          filterValueId: (el.filterValueId || []).map((el) => el.id),
        })),
      ],
    };
    createJobPostingMutation.mutate(transformedData);
  };

  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate(-1);
    } else {
      setPrevNextStep(-1);
    }
  };

  useEffect(() => {
    return () => {
      setCurrentStep(1);
    };
  }, []);
  return (
    <Layout>
      <PageBackComponent
        onBackButton={handleBackButton}
        title={t('jobPosting.postAJob')}
      />{' '}
      <FormCreationStepper currentStep={currentStep} stepsAmount={4} />
      <JobPostingForm onSubmit={onSubmit} />
      <CreationConfirmationModal
        openConfirmation={openConfirmation}
        handleClose={handleClose}
        title={t('jobPosting.successTitle')}
        congratulationText={t('jobPosting.successMessage')}
        info={t('jobPosting.successSubMessage')}
      />
    </Layout>
  );
};

export default JobPostingPage;

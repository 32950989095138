import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Typography } from '@mui/material';
import ExploreJobsPagination from '@/pages/ExplorePage/components/ExploreJobsPagination.tsx';
import ExploreOfferListItem from '@/components/common/ExploreOfferListItem';
import ExploreSearchWrapper from '@/pages/ExplorePage/components/ExploreSearchWrapper.tsx';
import ExploreOfferingsSearch from '@/pages/ExplorePage/components/ExploreOfferingsSearch.tsx';
import { useExploreOfferingQuery } from '@/api/offering/queries';
import { OfferingType } from '@/api/offering/types';
import { useTranslation } from 'react-i18next';

const ExploreOfferings = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [cachedData, setCachedData] = useState<{
    data: OfferingType[];
    page: number;
    totalPages: number;
  }>({ data: [], page: 1, totalPages: 1 });

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams]);

  const { data, isLoading } = useExploreOfferingQuery(queryParams);
  const offerings = data?.data;
  const cachedOfferings = cachedData?.data;
  const totalPages = cachedData?.totalPages || 1;

  useEffect(() => {
    if (offerings && !isLoading) {
      setCachedData(data);
    }
  }, [offerings, isLoading]);

  const noOfferingsFound = !cachedOfferings?.length && !isLoading;

  return (
    <>
      <ExploreSearchWrapper searchComponent={<ExploreOfferingsSearch />} />
      <Grid container spacing={2}>
        {cachedOfferings.map((offering) => (
          <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }} key={offering.id}>
            <ExploreOfferListItem
              id={offering.id}
              src={offering.medias?.[0]?.url || ''}
              title={offering.title}
              skills={offering.skills}
              price={offering.price}
              talent={offering.talent}
            />
          </Grid>
        ))}
        {noOfferingsFound && (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <Typography
              sx={{ color: 'purple1', textAlign: 'center', display: 'block' }}
              variant={'poppins20Semibold'}
            >
              {t('common.noFiltersFound', {
                item: t('common.offerings'),
              })}
            </Typography>
          </Box>
        )}
        {!!cachedOfferings.length && (
          <ExploreJobsPagination count={totalPages} />
        )}
      </Grid>
    </>
  );
};

export default ExploreOfferings;

import { apiRequest } from '@/libs/apiRequest.ts';
import { ExploreJobsParams } from '@/api/explore/types.ts';
import { TalentType } from '@/api/talents/types.ts';
import {UserType} from "@/types/user.ts";

export const fetchTalents = async (params: ExploreJobsParams) => {
  const response = await apiRequest<{
    data: TalentType[];
    page: number;
    totalPages: number;
  }>('/talents', {
    method: 'GET',
    withAuth: true,
    params,
  });

  if (response.error) {
    throw new Error(response.error);
  }
  return response.data;
};

export const saveTalentToFavorites = async (id: string) => {
  const response = await apiRequest<any>(`/talents/${id}/save`, {
    method: 'POST',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchTalent = async (id: string) => {
  const response = await apiRequest<UserType>(`/talents/${id}`, {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }
  return response.data;
};

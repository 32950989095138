import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { TalentExploreFilterFormType } from '@/pages/ExplorePage/components/TalentsFilter.tsx';
import { useEffect } from 'react';
import FormSkills from '@/components/forms/FormSkills';

const SkillsFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useFormContext<TalentExploreFilterFormType>();
  const { control, watch, setValue } = methods;
  const type = watch('skills');

  useEffect(() => {
    const skills = searchParams.get('skills');
    setValue('skills', skills ? skills.split(',') : []);
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (type) {
      newSearchParams.set('skills', type.join(','));
      newSearchParams.set('page', '1');
    } else {
      newSearchParams.delete('skills');
    }
    setSearchParams(newSearchParams);
  }, [type]);

  return (
    <FormSkills<TalentExploreFilterFormType>
      column
      name={'skills'}
      control={control}
    />
  );
};

export default SkillsFilter;

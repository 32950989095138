import { Box, styled, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import EditButton from '@/components/common/EditButton';
import { uploadFile } from '@/api/user/fetchers';
import * as Sentry from '@sentry/react';
import { PhotoFileType } from '@/types/user';
import { useTranslation } from 'react-i18next';
import { resizeImage } from '@/utils/resizeImage.ts';
import { StyledProgressLoader } from '@/styled';

export const UploadBox = styled(Box)({
  width: 100,
  height: 100,
  borderRadius: 99,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  backgroundColor: '#fafafb',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const UploadAvatar = ({
  id,
  imageUrl,
  onUpload,
  squared,
}: {
  id?: string;
  imageUrl?: string;
  onUpload: (value: PhotoFileType) => void;
  squared?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadedFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsLoading(true);
      const resizedImage = await resizeImage(file);

      const formData = new FormData();
      formData.append('file', resizedImage);

      try {
        const response = await uploadFile(formData);
        if (response?.file) {
          onUpload(response?.file);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        setIsLoading(false);
      }
    }
  };
  return (
    <UploadBox
      onClick={() => {
        inputRef.current?.click();
      }}
      sx={{
        backgroundImage: `url(${imageUrl})`,
        ...(squared && {
          borderRadius: '10px',
          maxWidth: '92px',
          maxHeight: '92px',
        }),
      }}
    >
      {isLoading && <StyledProgressLoader />}
      <EditButton
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          ...(squared && { top: '-10px', right: '-10px' }),
        }}
      />
      <input
        id={id}
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          handleUploadedFile(e);
        }}
      />
      {!imageUrl && (
        <>
          <Typography>{t('form.upload')}</Typography>
        </>
      )}
    </UploadBox>
  );
};

export default UploadAvatar;

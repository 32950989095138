import { PAGE_ROUTES } from '@/constants';
import { Box, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { truncateString } from '@/utils/truncateString';
import { StyledDivider, StyledTruncatedTypography } from '@/styled';
import AvatarNameLocation from '@/components/common/AvatarNameLocation';
import DollarSignIcon from '@/assets/icons/dolar_sign_icon.svg?react';
import { formatCurrency } from '@/utils/formatCurrency';
import { OfferingType } from '@/api/offering/types';
import { useTranslation } from 'react-i18next';
import { getLocationString } from '@/utils/getLocationString';
import EmptyImage from '@/components/common/EmptyImage';
import { useScrollContext } from '@/components/common/Layout/scrollContainerContext.tsx';

const ProfileInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: '10px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}));

type Props = Pick<
  OfferingType,
  'title' | 'skills' | 'price' | 'id' | 'talent'
> & {
  src: string;
};
const ExploreOfferListItem = ({
  title,
  src,
  skills,
  price,
  id,
  talent,
}: Props) => {
  const scrollContainerRef = useScrollContext();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const truncatedTitle = truncateString(title, 98);

  const userName = talent?.displayName ?? '';
  const location = talent?.location ? getLocationString(talent?.location) : '';

  return (
    <Box
      onClick={() => {
        navigate(`/${PAGE_ROUTES.OfferingDetails}/${id}`);
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }}
      sx={{
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderRadius: '15px',
        width: '100%',
        minHeight: '350px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      {src && (
        <Box
          component="img"
          sx={{
            width: '100%',
            height: '150px',
            objectFit: 'cover',
          }}
          alt="#"
          src={src}
        />
      )}
      {!src && <EmptyImage />}
      <Box sx={{ padding: '0 20px 20px 20px', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <StyledTruncatedTypography
            sx={{ display: 'block', marginTop: '20px', whiteSpace: 'normal' }}
            variant={'poppins16Medium'}
          >
            {truncatedTitle}
          </StyledTruncatedTypography>
          {!!skills?.length && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                marginTop: '15px',
                flexWrap: 'wrap',
              }}
            >
              {t('form.skills')}:{' '}
              {skills.map((el, index) => (
                <Typography
                  key={`${el}-${index}`}
                  sx={{ paddingTop: '3px' }}
                  variant={'poppins13Bold'}
                >
                  {el}
                  {skills.length > 1 && index !== skills.length - 1 && ','}
                </Typography>
              ))}
            </Box>
          )}
          <StyledDivider sx={{ marginY: '13px' }} />
          <ProfileInfoWrapper>
            <AvatarNameLocation
              src={talent?.photo?.url || ''}
              name={userName}
              location={location}
              locationSx={{ color: 'purple3', fontWeight: 700 }}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '3px',
                alignItems: 'center',
                color: 'purple3',
              }}
            >
              <DollarSignIcon />
              <Typography variant={'poppins13Bold'} sx={{ color: 'purple3' }}>
                {price ? `${formatCurrency(price)}$` : ''}
              </Typography>
            </Box>
          </ProfileInfoWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreOfferListItem;

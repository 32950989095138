import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { fetchPositions, getMe, updateProfile } from '@/api/user/fetchers';
import { UserType } from '@/types/user';
import { handleMutationError } from '@/utils/handleMutationError';
import { toast } from 'sonner';

export const useUserProfile = (isAuth: boolean, redirect?: () => void) => {
  return useQuery({
    queryKey: ['userProfile'],
    queryFn: () => getMe(redirect),
    enabled: isAuth,
  });
};

export const usePositionsQuery = (searchValue: string) => {
  return useQuery({
    queryKey: ['positions', searchValue],
    queryFn: () => fetchPositions(searchValue),
    enabled: !!searchValue.trim(),
    select: (data) => {
      return data?.map((position) => ({
        value: position.id,
        label: position.name,
      }));
    },
  });
};

export const useProfileMutation = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: updateProfile,
    onMutate: async (variables) => {
      // Cancel ongoing fetches for userProfile
      await queryClient.cancelQueries({ queryKey: ['userProfile'] });

      // Snapshot of the previous value
      const previousData: UserType | undefined = queryClient.getQueryData([
        'userProfile',
      ]);

      // Optimistic update for UI if locationId hasn't changed
      if (variables?.locationId === previousData?.location?.id) {
        queryClient.setQueryData(['userProfile'], {
          ...previousData,
          ...variables,
        });
      }

      // Return snapshot to rollback in case of error
      return { previousData };
    },
    onError: (error, _, context) => {
      // Rollback to previous data if mutation fails
      queryClient.setQueryData(['userProfile'], context?.previousData);
      handleMutationError(error);
    },
    onSuccess: (_, variables) => {
      // Invalidate profile data if locationId has changed
      const profileData: UserType | undefined = queryClient.getQueryData([
        'userProfile',
      ]);
      if (variables?.locationId !== profileData?.location?.id) {
        queryClient.invalidateQueries({ queryKey: ['userProfile'] });
      }
      toast.success('Profile updated successfully.');
    },
    onSettled: () => {
      // Always ensure data consistency
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
    },
  });
};

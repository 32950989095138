import { Box, Button, styled } from '@mui/material';
import { useCategoriesQuery } from '@/api/jobPosting/queries';
import { useLocation, useSearchParams } from 'react-router-dom';
import SelectableList, {
  SelectableItem,
} from '@/components/common/SelectableList';
import ExploreFilterSection from '@/components/common/ExploreFilter/ExploreFilterSection.tsx';
import LocationFilter from '@/components/common/ExploreFilter/LocationFilter.tsx';
import { FormProvider, useForm } from 'react-hook-form';
import JobAvailabilityFilter from '@/components/common/ExploreFilter/JobAvailabilityFilter.tsx';
import PriceFilter from '@/components/common/ExploreFilter/PriceFilter.tsx';
import { useTranslation } from 'react-i18next';
import { PAGE_ROUTES } from '@/constants';

export const FilterContainer = styled(Box)(() => ({
  borderRadius: '15px',
  width: '100%',
  backgroundColor: '#fff',
  minHeight: '780px',
  padding: '20px',
  position: 'relative',
}));

export const ResetButton = styled(Button)(() => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  padding: 0,
  minHeight: 'initial',
  fontWeight: 400,
  fontSize: '16px',
}));

export type ExploreFilterFormType = {
  locationId?: string;
  locationSearch?: string;
  availability?: string;
  fixedBudget?: number;
  [key: string]: any;
};

const filterKeys: (keyof ExploreFilterFormType)[] = [
  'locationId',
  'locationSearch',
  'availability',
  'price',
  'categoryId',
];

const resetFilterState = {
  locationId: '',
  locationSearch: '',
  availability: '',
  fixedBudget: 0,
};
const ExploreFilter = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = new URLSearchParams(search);
  const selectedCategoriesIds = query.get('categoryId') ?? '';
  const { data: categories } = useCategoriesQuery();

  const { pathname } = useLocation();
  const showJobsFilters = pathname.includes(PAGE_ROUTES.ExploreJobs);

  const selectedCategories = categories?.filter((a) =>
    selectedCategoriesIds.split(',').includes(a?.id)
  );

  const methods = useForm<ExploreFilterFormType>();

  const { reset } = methods;

  const onChangeCategory = (updatedCategories: SelectableItem[]) => {
    const newSearchParams = new URLSearchParams(searchParams);

    const updatedIds = updatedCategories
      .map((category) => category.id)
      .join(',');
    newSearchParams.set('categoryId', updatedIds);
    newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
  };

  const onResetFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    filterKeys.forEach((filterKey) => {
      newSearchParams.delete(filterKey as string);
    });
    // Reset page
    newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
    reset(resetFilterState);
  };


  return (
    <FilterContainer>
      <ExploreFilterSection title={t('jobPosting.categories')}>
        <SelectableList
          items={categories || []}
          selectedValues={selectedCategories || []}
          isSingleSelect={true}
          onChange={(updatedValues) => {
            onChangeCategory(updatedValues);
          }}
        />
      </ExploreFilterSection>
      <FormProvider {...methods}>
        <LocationFilter />
        {showJobsFilters && (
          <>
            <JobAvailabilityFilter />
            <PriceFilter />
          </>
        )}
      </FormProvider>
      <ResetButton onClick={onResetFilters}>{t('common.reset')}</ResetButton>
    </FilterContainer>
  );
};

export default ExploreFilter;

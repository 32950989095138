import { Box, styled } from '@mui/material';
import PageBackComponent from '@/components/common/PageBackComponent';
import HirerProfileInfo from '@/pages/ProfilePage/components/hirer/HirerProfileInfo.tsx';
import JobPostedList from '@/pages/ProfilePage/components/hirer/JobPostedList.tsx';
import { useMyJobsQuery } from '@/api/jobPosting/queries';
import { useTranslation } from 'react-i18next';

export const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '40px',
  gap: '20px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const HirerProfile = () => {
  const { t } = useTranslation();
  const { data: jobs } = useMyJobsQuery();
  return (
    <Box>
      <PageBackComponent title={t('pageTitles.hirerProfile')} />
      <ProfileContainer>
        <HirerProfileInfo />
        <JobPostedList jobs={jobs || []} />
      </ProfileContainer>
    </Box>
  );
};

export default HirerProfile;

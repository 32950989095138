import { Box, Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useScrollContext } from '@/components/common/Layout/scrollContainerContext.tsx';
import { useEffect } from 'react';

const ExploreJobsPagination = ({ count }: { count: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollContainerRef = useScrollContext();

  // Get current page from query params or default to 1
  const page = Number(searchParams.get('page')) || 1;

  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', newPage.toString());
    setSearchParams(newSearchParams);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };


  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams({ ...Object.fromEntries(searchParams), page: '1' });
    }
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        marginTop: '20px',
      }}
    >
      <Pagination
        size="large"
        page={page}
        count={count}
        shape="rounded"
        onChange={handlePageChange}
      />
    </Box>
  );
};

export default ExploreJobsPagination;

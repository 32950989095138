import React from 'react';
import ExploreFilter from '@/components/common/ExploreFilter';
import { Button, Drawer } from '@mui/material';

const MobileExploreJobFilter = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <>
      <Button onClick={toggleDrawer(true)}>Filter</Button>
      <Drawer
        PaperProps={{
          sx: { width: '80%', maxWidth: '400px' },
        }}
        anchor={'right'}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <ExploreFilter />
      </Drawer>
    </>
  );
};

export default MobileExploreJobFilter;

import { useFormContext } from 'react-hook-form';
import { TalentFields } from '@/pages/OnboardingPage/components/TalentForm/index.tsx';
import { FieldsContainer } from '@/styled';
import FormInput from '@/components/forms/FormInput';
import SkillsInput from '@/components/forms/FormSkills';
import FormSocials from '@/components/forms/FormSocialLinks';
import FormDating from '@/components/forms/FormDating';
import FormSelect from '@/components/forms/FormSelect';
import {
  getAvailabilitySelectOptions,
} from '@/constants';
import { useTranslation } from 'react-i18next';

const ThirdTalentStep = () => {
  const { control } = useFormContext<TalentFields>();
  const { t } = useTranslation();

  return (
    <FieldsContainer>
      <FormInput<TalentFields>
        name="position"
        control={control}
        label={t('form.currentPosition')}
        placeholder={t('form.enterCurrentPosition')}
      />
      <SkillsInput name="skills" control={control} label={t('form.skills')} />
      <FormInput<TalentFields>
        name="bio"
        control={control}
        label={t('form.about')}
        multiline
        rows={4}
        placeholder={t('form.enterAboutYourself')}
      />
      <FormSocials<TalentFields>
        name="socialLinks"
        control={control}
        label={t('form.socials')}
      />
      <FormDating<TalentFields>
        name="dating"
        control={control}
        label={t('form.dating')}
      />
      <FormSelect<TalentFields>
        name="availableFor"
        control={control}
        label={t('form.availableFor')}
        placeholder={t('form.selectAvailability')}
        options={getAvailabilitySelectOptions(t)}
      />
    </FieldsContainer>
  );
};

export default ThirdTalentStep;

import { apiRequest } from '@/libs/apiRequest';
import {
  CategoryType,
  FilterDataBodyType,
  JobDetailsType,
} from '@/api/jobPosting/types';
import { SubcategoryType } from '@/types/filters';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm';

export const fetchCategories = async () => {
  const response = await apiRequest<CategoryType[]>('/categories', {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchSubcategories = async (categoryId: string) => {
  const response = await apiRequest<SubcategoryType[]>('/subcategories', {
    method: 'GET',
    withAuth: true,
    params: { categoryId: categoryId },
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const createJobPosting = async (
  body: Omit<JobCategoryFormInputs, 'selectedCategoryId' | 'data' | 'locationSearch'> & {
    data: FilterDataBodyType;
  }
) => {
  const response = await apiRequest<JobDetailsType>('/jobs', {
    method: 'POST',
    withAuth: true,
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
export const editJobPosting = async ({
  id,
  body,
}: {
  id: string;
  body: Omit<JobCategoryFormInputs, 'selectedCategoryId' | 'data' | 'locationSearch'> & {
    data: FilterDataBodyType;
  };
}) => {
  const response = await apiRequest<JobDetailsType>(`/jobs/${id}`, {
    method: 'PUT',
    withAuth: true,
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchJobPosting = async (id: string) => {
  const response = await apiRequest<JobDetailsType>(`/jobs/${id}`, {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchMyJobs = async () => {
  const response = await apiRequest<JobDetailsType[]>(`/user/jobs`, {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const deleteJobPosting = async (id: string) => {
  const response = await apiRequest<{ message: string }>(`/jobs/${id}`, {
    method: 'DELETE',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
